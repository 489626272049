import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Menu,
  Button,
  Modal,
  message,
  Avatar,
  Pagination,
  Empty,
} from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { QRCodeCanvas } from "qrcode.react";

import {
  ENM_ORDER_STATE,
  ENM_PAY_STATE,
  ENM_ORDER_PAY_TYPE,
} from "../../../common/enum";
import {
  GET_ORDER_LIST,
  GET_ORDER_DETAIL,
  PAY_ORDER,
} from "../../../api/order";

const Order = (props) => {
  const {} = props;
  const [userInfo, setUserInfo] = useState();
  const [visibleInfo, setVisibleInfo] = useState({});
  const [visibleCreate, setVisibleCreate] = useState(false);

  const [price, setPrice] = useState({});
  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const qrcodeTimeRef = useRef();

  const [list, setList] = useState([{}]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_ORDER_LIST({ ...paginations });
    if (code == 200) {
      setList(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };

  // 分页操作
  const onPageChange = (page) => {
    setPagination({
      ...pagination,
      pageNo: page,
    });
    getList({
      ...pagination,
      pageNo: page,
    });
  };

  // 支付订单
  const toPay = async (orderId) => {
    const {
      data: { code, data, msg },
    } = await PAY_ORDER({
      id: orderId,
    });

    if (200 == code) {
      setQrcodeVisible(true);
      setQrcodeUrl(data?.code_url);
    } else {
      msg && message.error(msg);
    }

    getList(pagination);
  };

  // 关闭扫码框
  const closeQrcode = () => {
    setQrcodeVisible(false);
    setVisibleInfo({});
  };

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    } else {
      setUserInfo({});
    }
  }, [localStorage.getItem("userInfo")]);

  useEffect(() => {
    getList(pagination);
  }, []);

  useEffect(() => {
    document.title = "用户中心 - 订单管理";
  }, []);

  return (
    <div className="order context">
      <div className="head">
        <div className="item intro">订单信息</div>
        <div className="item amount">小计</div>
        <div className="item contact">收货人</div>
        <div className="item user">联系方式</div>
        <div className="item actions">操作</div>
      </div>
      <div className="body">
        <div className="list">
          {list.map((item, index) => (
            <div className="list-item" key={index}>
              <div className="head">
                <div className="order">
                  <div className="item time">
                    <span className="label">下单时间：</span>
                    <span className="value">{item.created_at}</span>
                  </div>
                  <div className="item code">
                    <span className="label">订单号：</span>
                    <span className="value">{item.order_code}</span>
                  </div>
                </div>
                <div className="address">
                  <div className="item address">
                    <span className="value">
                      {item.provinces_name}
                      {item.cities_name}
                      {item.areas_name}
                      {item.address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="order">
                <div className="item amount">
                  <span className="label">订单金额：</span>
                  <span className="value">{item.pay_money}</span>
                </div>
                <div className="item state">
                  <span className="label">订单状态：</span>
                  <span className="value">{ENM_ORDER_STATE(item.state)}</span>
                </div>
                <div className="item paystate">
                  <span className="label">支付状态：</span>
                  <span className="value">{ENM_PAY_STATE(item.pay_state)}</span>
                </div>
                <div className="item paytype">
                  <span className="label">支付方式：</span>
                  <span className="value">
                    {ENM_PAY_STATE(
                      item.pay_state,
                      ENM_ORDER_PAY_TYPE(item.pay_type)
                    )}
                  </span>
                </div>
                <div className="item paytime">
                  <span className="label">支付时间：</span>
                  <span className="value">
                    {ENM_PAY_STATE(item.pay_state, item.pay_time || "-")}
                  </span>
                </div>
              </div>
              <table className="goods-list">
                <tbody>
                  {item?.goods_sku &&
                    JSON.parse(item.goods_sku).map((sku, gInd) => {
                      const length = JSON.parse(item.goods_sku).length;
                      // const length = 3;
                      const goods = JSON.parse(item.goods);
                      // console.log(goods)
                      const goodsIndex = goods.findIndex(
                        (it) => it.id === sku.goods_id
                      );

                      const skus = [];
                      const skuInfo = JSON.parse(sku.sku);
                      Object.keys(skuInfo).map((it, ind) => {
                        if (it.indexOf("skuValue") >= 0) {
                          let skuIndex = it.split("skuValue")[1];
                          skus.push(
                            `${skuInfo["skuName" + skuIndex]}:${skuInfo[it]}`
                          );
                        }
                      });
                      return (
                        <tr className="goods-list-item" key={gInd}>
                          <td className="intro">
                            <div className="item intro">
                              <div className="cont">
                                <div className="cover">
                                  <img src={goods[goodsIndex]?.cover} />
                                </div>
                                <div className="info">
                                  <div
                                    className="title"
                                    title={goods[goodsIndex]?.name}
                                  >
                                    {goods[goodsIndex]?.name}
                                  </div>
                                  <div className="sku" title={skus.join(",")}>
                                    {skus.map((sItem, sInd) => (
                                      <div index={sInd}>{sItem}</div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="number">
                                <div className="amount">
                                  ￥{skuInfo.event_price || skuInfo.sale_price}
                                  <small
                                    className="ml-1"
                                    style={{ color: "#999" }}
                                  >
                                    [单价]
                                  </small>
                                  + ￥
                                  {goods[goodsIndex].is_free_shipping
                                    ? 0
                                    : goods[goodsIndex].postage}
                                  <small
                                    className="ml-1"
                                    style={{ color: "#999" }}
                                  >
                                    [邮费]
                                  </small>
                                </div>
                                <div className="num text-right">
                                  x {sku?.number}
                                  <small
                                    className="ml-1"
                                    style={{ color: "#999" }}
                                  >
                                    [数量]
                                  </small>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="amount">
                            <div className="item amount">
                              ￥
                              {(
                                ((skuInfo.event_price || skuInfo.sale_price) +
                                  (goods[goodsIndex].is_free_shipping
                                    ? 0
                                    : goods[goodsIndex].postage)) *
                                sku?.number
                              ).toFixed(2)}
                            </div>
                          </td>
                          <td
                            className="contact"
                            rowSpan={length}
                            style={{
                              display: (gInd == 0 && "table-cell") || "none",
                            }}
                          >
                            <div className="item contact">
                              <div className="name">{item.contact_name}</div>
                            </div>
                          </td>
                          <td
                            className="user"
                            rowSpan={length}
                            style={{
                              display: (gInd == 0 && "table-cell") || "none",
                            }}
                          >
                            <div className="item user">
                              <div className="mobile">
                                {item.contact_mobile}
                              </div>
                            </div>
                          </td>
                          <td
                            className="actions"
                            rowSpan={length}
                            style={{
                              display: (gInd == 0 && "table-cell") || "none",
                            }}
                          >
                            <div className="item actions">
                              <Button type="link" size="small">
                                订单详情
                              </Button>
                              {item.pay_state === 0 && item.state === 1 && (
                                <Button
                                  type="link"
                                  size="small"
                                  onClick={() => {
                                    setVisibleInfo(item);
                                    toPay(item.id);
                                  }}
                                >
                                  支付
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {(item.express_company && item.express_number && (
                <div className="express">
                  <span className="item">快递公司：{item.express_company}</span>
                  <span className="item">快递单号：{item.express_number}</span>
                </div>
              )) ||
                ""}
            </div>
          ))}
        </div>
        {!list.length && (
          <div className="empty py-5">
            <Empty />
          </div>
        )}
      </div>
      <div className="foot">
        {!!list.length && (
          <div className="pagination text-right">
            <Pagination
              current={pagination.pageNo}
              total={pagination.total}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </div>
        )}
      </div>

      {(qrcodeVisible && (
        <Modal
          open={qrcodeVisible}
          width={600}
          closable={false}
          footer={[
            <div
              className="price-info text-left"
              style={{
                position: "absolute",
                left: "15px",
                marginTop: "0px",
              }}
            >
              <div>
                <span className="label">实付金额：</span>
                <span
                  className="price"
                  style={{
                    fontSize: 20,
                    fontWeight: "bolder",
                    marginRight: "15px",
                  }}
                >
                  ￥{visibleInfo.pay_money || "0.00"}
                </span>
              </div>
            </div>,
            <Button
              onClick={() => {
                closeQrcode();
              }}
            >
              未完成支付
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                closeQrcode();
              }}
            >
              已完成支付
            </Button>,
          ]}
        >
          <div className="tips mb-5">
            请确认您购买授权后将不支持退款，支付后相关款项会从微信中扣除，如果您不确定是否有强烈的需求，请您先体验30天后转为正式授权即可。
          </div>
          <div className="text-center">
            <QRCodeCanvas width={300} value={qrcodeUrl} />
          </div>
        </Modal>
      )) ||
        ""}
    </div>
  );
};

export default Order;
