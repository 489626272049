import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Download = (props) => {
  const {} = props;

  const [intros, setIntors] = useState([
    {
      name: "即时消息",
      sub: "极致高效传递信息",
    },
    {
      name: "文档",
      sub: "多人实时编辑共创",
    },
    {
      name: "视频会议",
      sub: "线上会议如临现场",
    },
    {
      name: "思维笔记",
      sub: "灵感快速记录",
    },
    {
      name: "知识库",
      sub: "知识持续创造价值",
    },
    {
      name: "日历",
      sub: "轻松掌握团队日程",
    },
    {
      name: "多维表格",
      sub: "轻松搭建业务系统",
    },
    {
      name: "任务",
      sub: "一起都有始有终",
    },
  ]);
  const [curIntro, setCurIntro] = useState(0);

  const [videoNav, setVideoNav] = useState(["智慧业务管理", "高效办公协作"]);
  const [curVideoNav, setCurVideoNav] = useState(0);

  const [services, setServices] = useState([
    {
      title: "人工服务",
      tag: "付费版",
      tip1: "7x24h",
      tip2: "在线客服解答",
    },
    {
      title: "技术支持",
      tag: "含周末",
      tip1: "工作时段",
      tip2: "1v1 在线支持",
    },
    {
      title: "帮助中心",
      tag: "",
      tip1: "员工自助答疑",
      tip2: "降低企业运营成本",
    },
    {
      title: "售前专家",
      tag: "",
      tip1: "根据您的需求",
      tip2: "制定解决方案",
    },
    {
      title: "效能顾问",
      tag: "",
      tip1: "定位组织问题",
      tip2: "制定效能提升方案",
    },
    {
      title: "咨询团队",
      tag: "",
      tip1: "提供组织管理升级",
      tip2: "咨询服务",
    },
  ]);

  const [contacts, setContacts] = useState([
    "分享先进工作方式",
    "输送行业最佳实践",
    "全面协助组织提效",
  ]);

  useEffect(() => {
    document.title =
      "FileStation Manager 解决方案 - 七朵云 - 存储解决方案服务商";
  }, []);

  return (
    <div className="solution context">
      <div className="banner">
        <Carousel autoplay>
          <div className="item view">
            <div className="bg">
              <video
                className="video"
                controls={false}
                muted={true}
                loop
                autoPlay
                src={
                  require(`../../../assets/image/solution/banner-1-bg.mp4`)
                    .default
                }
              ></video>
            </div>
            <div className="cont">
              <div className="info">
                <div className="title">
                  <img
                    src={
                      require("../../../assets/image/solution/banner-1-title-icon.svg")
                        .default
                    }
                  />
                  <span className="txt">Workspace</span>
                </div>
                <div className="subtitle">
                  <span className="txt">智慧企业</span>
                  <span className="txt color">专属空间</span>
                </div>
                <div className="action">
                  <Button type="primary">
                    <span>预约演示</span>
                    <img
                      src={
                        require("../../../assets/image/solution/banner-1-action-icon.svg")
                          .default
                      }
                    />
                  </Button>
                </div>
              </div>
              <div className="cover">
                <img
                  src={
                    require("../../../assets/image/solution/banner-1-cover.png")
                      .default
                  }
                />
              </div>
            </div>
          </div>
          {/* <div className="item">
            <div className="info">2</div>
            <div className="cover"></div>
          </div> */}
        </Carousel>
      </div>
      <div className="intro">
        <div className="container">
          <div className="title">企业协作与管理平台</div>
          <div className="intro-list">
            {intros.map((item, ind) => (
              <div
                className={`intro-list-item ${ind === curIntro && "cur"}`}
                key={ind}
                onMouseEnter={() => {
                  setCurIntro(ind);
                }}
              >
                <div className="cover">
                  <img
                    src={
                      require(`../../../assets/image/solution/intro-${ind}.svg`)
                        .default
                    }
                  />
                </div>
                <div className="name">{item.name}</div>
                <div className="sub">{item.sub}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="videos">
        <div className="container">
          <div className="nav">
            {videoNav.map((item, ind) => (
              <div
                className={`nav-item ${ind == curVideoNav && "cur"}`}
                key={ind}
                onClick={() => {
                  setCurVideoNav(ind);
                }}
              >
                {item}
              </div>
            ))}
            <div
              className="nav-cur-bg"
              style={{ left: 216 * curVideoNav }}
            ></div>
          </div>
          <div className="cont">
            <div className="cont-item">
              <video
                className="video"
                controls={false}
                muted={true}
                loop
                autoPlay
                src={
                  require(`../../../assets/image/solution/video-0.mp4`).default
                }
              ></video>
            </div>
            <div className="cont-item">
              <video
                className="video"
                controls={false}
                muted={true}
                loop
                autoPlay
                src={
                  require(`../../../assets/image/solution/video-1.mp4`).default
                }
              ></video>
            </div>
          </div>
        </div>
      </div>
      <div className="service">
        <div className="container">
          <div className="title">企业数字化服务在线</div>
          <div className="service-list">
            {services.map((item, ind) => (
              <div className="service-list-item" key={ind}>
                <div className="cont">
                  <div className="info">
                    <div className="title">{item.title}</div>
                    {item.tag && <div className="tag">{item.tag}</div>}
                  </div>
                  <div className="sub">{item.tip1}</div>
                  <div className="sub">{item.tip2}</div>
                </div>
                <div className="cover">
                  <img
                    src={
                      require(`../../../assets/image/solution/service-${ind}.png`)
                        .default
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="tip">我们将根据您购买情况提供相应上述服务</div>
        </div>
      </div>
      <div className="contact">
        <div className="container">
          <div className="title">FileStation Manager 智能工作空间</div>
          <div className="subtitle">联系智慧企业数字化顾问为您提供全力支持</div>
          <div className="list">
            {contacts.map((item, ind) => (
              <div className="list-item" key={ind}>
                <div className="icon">
                  <img
                    src={
                      require("../../../assets/image/solution/yes.svg").default
                    }
                  />
                </div>
                <div className="txt">{item}</div>
              </div>
            ))}
          </div>
          <div className="actions">
            <Button type="primary">联系我们</Button>
            <Button type="ghost" href="/download">
              下载客户端
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
