import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import { GET_COUPON_LIST, RECEIVE_COUPON } from "../../../api/coupon";

const Coupon = (props) => {
  const {} = props;

  const [list, setList] = useState([]);

  const getList = async () => {
    const {
      data: { data, code, msg },
    } = await GET_COUPON_LIST({});

    if (200 === code) {
      setList(data.resource);
    }
  };

  const receive = async (item) => {
    const {
      data: { data, code, msg },
    } = await RECEIVE_COUPON({
      id: item.id,
    });

    if (200 === code) {
      getList();
      message.success("领取成功");
      return;
    }

    message.error(msg);
  };

  useEffect(() => {
    document.title =
      "FileStation Manager 领券中心 - 七朵云 - 存储解决方案服务商";

    getList();
  }, []);

  return (
    <div className="coupon context">
      {/* <div className="carousel">
        <Carousel autoplay afterChange={onChange}>
          <div className="cover-img">
            <img
              src={require("../../../assets/image/banner/banner1.jpg").default}
              style={{ width: "100%" }}
            />
          </div>
        </Carousel>
      </div> */}
      <div className="banner">
        <img
          src={require("../../../assets/image/coupon/banner.webp").default}
          style={{ width: "100%" }}
        />
      </div>
      <div className="list">
        <div className="container">
          <div className="d-list">
            {list.map((item, ind) => (
              <div
                className="d-list-item"
                key={ind}
                onClick={() => receive(item)}
              >
                <div className="cont">
                  <div></div>
                  <div className="title">{item.title}</div>
                  <div className="end">
                    {(item.end_time && `${item.end_time} 后过期`) ||
                      `领取后 ${item.end_day} 天内有效`}
                  </div>
                </div>
                <div className="info">
                  <div className="threshold">
                    {(item.threshold_amount > 0 && (
                      <span>满{Number(item.threshold_amount)}元</span>
                    )) || <span>无门槛限制</span>}
                  </div>
                  <div className="txt">减</div>
                  <div className="amount">{Number(item.amount)}</div>
                  <div className="range">
                    {(item.use_range == 0 && "全场通用") ||
                      (item.use_range == 1 && "限商品可用") ||
                      (item.use_range == 2 && "限授权可用")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
