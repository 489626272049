import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import { QRCodeCanvas } from "qrcode.react";

import { CREATE_JOINUS_ORDER } from "../../../api/goods";

const Active = (props) => {
  const {} = props;

  const [covers, setCovers] = useState([
    {
      key: 0,
      label: "机架式",
      cover: "",
    },
    {
      key: 1,
      label: "桌面式",
      cover: "",
    },
  ]);
  const [curCover, setCurCover] = useState(0);

  useEffect(() => {
    document.title = "FileStationOS - 专为家庭与企业设计的存储系统";
  }, []);
  return (
    <div className="event-d2191m context">
      <div className="screen">
        <div className="title">D2191M</div>
        <div className="subtitle">一款面向专业玩家的存储系列主板</div>
        <div className="button">
          <Button href="/product/detail/45" target="_blank" ghost>
            现在预定
          </Button>
        </div>
        <div className="cover-list">
          {covers.map((item, index) => (
            <div
              className={`cover-list-item ${curCover == item.key ? "cur" : ""}`}
              key={index}
            >
              <img
                className="cover"
                src={
                  require(`../../../assets/image/event/d2191m/cover-${item.key}.svg`)
                    .default
                }
              />
            </div>
          ))}
        </div>
        <div className="action-list">
          {covers.map((item, index) => (
            <div
              className={`action-list-item ${
                curCover == item.key ? "cur" : ""
              }`}
              key={index}
              onClick={() => setCurCover(item.key)}
            >
              {item.label}
            </div>
          ))}
          <div
            className="line"
            style={{ left: `calc(50% * ${curCover} - 2px)` }}
          ></div>
        </div>
        <div className="text-list">
          <div className="text-list-item">19英寸4U设计</div>
          <div className="text-list-item">玩家定制1024专用机箱</div>
          <div className="text-list-item">600MM深度机柜适用</div>
        </div>
      </div>
      <div className="block desc">
        <div className="info">
          <div className="title">D2191M</div>
          <div className="subtitle">令人心动的超高性价比的万兆存储主板</div>
          <div className="button">
            <Button href="/product/detail/45" target="_blank" ghost>
              立即预约
            </Button>
          </div>
        </div>
        <div className="cover">
          <img
            src={
              require("./../../../assets/image/event/d2191m/image2.svg").default
            }
          />
        </div>
      </div>
      <div className="block intro">
        <div className="box">
          <div className="info">
            <div className="title">D2191M</div>
            <div className="subtitle">专用操作系统</div>
          </div>
          <div className="cover">
            <img
              src={
                require("./../../../assets/image/event/d2191m/image3.svg")
                  .default
              }
            />
          </div>
        </div>
        <div className="bottom">FSM操作系统为D2191M提供强劲动力</div>
      </div>
    </div>
  );
};

export default Active;
