import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import { GET_CHANNEL_LIST } from "../../../api/channel";

const Channel = (props) => {
  const {} = props;

  const [navbar, setNavbar] = useState([]);
  const [menuKey, setMenuKey] = useState("all");

  const [list, setList] = useState([]);

  const getList = async () => {
    const {
      data: { data, code, msg },
    } = await GET_CHANNEL_LIST({
      pageNo: 1,
      pageSize: 999999,
    });

    if (200 === code) {
      setNavbar([
        { label: "全部", key: "all" },
        ...Array.from(
          new Set([
            ...data.resource.map((item) => {
              return item.type;
            }),
          ])
        ).map((item) => ({
          label: item,
          key: item,
        })),
      ]);

      setList(data.resource);
    }
  };

  useEffect(() => {
    document.title =
      "FileStation Manager 销售渠道 - 七朵云 - 存储解决方案服务商";

    getList();
  }, []);

  const onMenuClick = (e) => {
    // console.log("click ", e);
    setMenuKey(e.key);
  };
  return (
    <div className="channel context">
      {/* <div className="carousel">
        <Carousel autoplay afterChange={onChange}>
          <div className="cover-img">
            <img
              src={require("../../../assets/image/banner/banner1.jpg").default}
              style={{ width: "100%" }}
            />
          </div>
        </Carousel>
      </div> */}
      <div className="banner"></div>
      <div className="navbar">
        <Menu
          className="container"
          mode="horizontal"
          items={navbar}
          selectedKeys={[menuKey]}
          onClick={onMenuClick}
        />
      </div>
      <div className="list">
        <div className="container">
          <div className="d-list">
            {list.map(
              (item, ind) =>
                (
                  <div
                    className="d-list-item"
                    style={{
                      display:
                        ((item.type == menuKey || menuKey == "all") &&
                          "block") ||
                        "none",
                    }}
                    key={ind}
                  >
                    <div className="header">
                      <div className="net">
                        <img
                          src={
                            (item?.net_type &&
                              require(`../../../assets/image/channel/${
                                (item?.net_type == 1 && "jd") ||
                                (item?.net_type == 2 && "pdd") ||
                                (item?.net_type == 3 && "tmall") ||
                                (item?.net_type == 4 && "taobao") ||
                                "none"
                              }.svg`).default) ||
                            ""
                          }
                        />
                      </div>
                    </div>
                    <div className="content">
                      <div className="name">{item.name}</div>
                      {item?.address && (
                        <div className="desc address">
                          <img
                            className="icon"
                            src={
                              require("../../../assets/image/channel/address.svg")
                                .default
                            }
                          />
                          <span className="txt">{item.address}</span>
                        </div>
                      )}
                      {item?.mobile && (
                        <div className="desc mobile">
                          <img
                            className="icon"
                            src={
                              require("../../../assets/image/channel/mobile.svg")
                                .default
                            }
                          />
                          <span className="txt">
                            {item.mobile}
                            {item.contact}
                          </span>
                        </div>
                      )}
                      <div className="desc url">
                        <img
                          className="icon"
                          src={
                            require("../../../assets/image/channel/network.svg")
                              .default
                          }
                        />
                        <a
                          className="txt"
                          href={`//${item.url}`}
                          target="_blank"
                        >
                          {(item.type === "网络渠道" && "点击购买") || item.url}
                        </a>
                      </div>
                    </div>
                  </div>
                ) || ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Channel;
