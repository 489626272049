import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
/**
 * 获取设备授权列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_DEVICE_AUTH_LIST = async (parm) =>
  await instance.post(`${base}/user/usercenter/deviceauth/auths`, parm);

/**
 * 获取设备授权列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_DEVICE_REG_LIST = async (parm) =>
  await instance.post(`${base}/user/usercenter/deviceauth/list`, parm);

/**
 * 获取设备授权详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_DEVICE_AUTH_DETAIL = async () =>
  await instance.get(`${base}/user/usercenter/deviceauth/detail`);

/**
 * 设置设备授权
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 设备授权项ID
 * @param {String} parm.deviceNo 设备授权号
 * @param {String} parm.deviceDesc 设备授权描述
 * @param {String} parm.url 设备授权地址
 * @param {String} parm.system 系统
 * @param {String} parm.isFullPackage 是否全量包 0:否 1:是Uncaught ReferenceError: process is not defined
 * @returns
 */
export const SET_DEVICE_AUTH = async (parm) =>
  await instance.post(`${base}/user/usercenter/deviceauth/set`, parm);

/**
 * 获取设备授权价格
 * @returns
 */
export const GET_PRICE = async (parm) =>
  await instance.get(
    `${base}/user/usercenter/deviceauth/price?${qs.stringify(parm)}`,
    parm
  );

/**
 * 购买设备授权
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 设备授权项ID
 * @returns
 */
export const BUY_DEVICE_AUTH = async (parm) =>
  await instance.post(`${base}/user/usercenter/deviceauth/buy`, parm);

/**
 * 绑定设备授权授权
 * @param {Object} parm 请求参数
 * @param {String} parm.sn 注册设备序列号
 * @param {String} parm.secret 绑定授权密钥
 * @returns
 */
export const BIND_DEVICE_AUTH = async (parm) =>
  await instance.post(`${base}/user/usercenter/deviceauth/bind`, parm);

/**
 * 重置设备授权授权
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 设备授权项ID
 * @returns
 */
export const RESET_DEVICE_AUTH = async (parm) =>
  await instance.post(`${base}/user/usercenter/deviceauth/reset`, parm);

/**
 * 删除设备授权
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const DEL_DEVICE_AUTH = async (parm) =>
  await instance.delete(`${base}/user/usercenter/deviceauth/del`, {
    data: { ...parm },
  });
