import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Os = (props) => {
  const {} = props;

  const contentStyle = {
    height: "460px",
    color: "#fff",
    lineHeight: "460px",
    textAlign: "center",
    background: "#364d79",
  };

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  useEffect(() => {
    document.title = "FileStationOS - 专为家庭与企业设计的存储系统";
  }, []);
  return (
    <div className="os context">
      <div className="screen">
        <div className="cover">
          <img
            className="img"
            src="https://qiduo-1251034078.cos.ap-shanghai.myqcloud.com/bananer%2Fos_index.png"
          />
        </div>
        <div className="title">
          <div>FileStationOS</div>
          <div className="subtitle">专为存储场景设计的WebOS</div>
        </div>
        <div className="actions">
          <Button
            href="https://www.123pan.com/s/ChnUVv-Csq53.html"
            target="_blank"
            type="primary"
          >
            下载安装
          </Button>
          <Button href="https://txc.qq.com/products/409204" target="_blank">
            社区反馈
          </Button>
        </div>
      </div>
      <div className="context-item drive-intro">
        <div className="top-title">设备支持、一个都不能少</div>
        <div className="content">
          <div className="title">真正支持全面的存储系统</div>
          <div className="desc">
            FileStationOS
            基于Linux开发，目前已经支持Intel、AMD、龙芯、瑞芯微等厂商CPU的方案下使用，更多适配正在进行中
          </div>
          <div className="drive-list">
            <div className="drive-list-item">Intel</div>
            <div className="drive-list-item">AMD</div>
            <div className="drive-list-item">龙芯</div>
            <div className="drive-list-item">瑞芯微</div>
            <div className="drive-list-item">海思</div>
            <div className="drive-list-item">虚位以待</div>
          </div>
        </div>
      </div>
      <div className="context-item function-intro">
        <div className="top-title">该有的功能、一个都不少</div>
        <div className="content">
          <div className="function-list">
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function1.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function2.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function3.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function4.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function5.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function6.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function7.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function8.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function9.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function10.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function11.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function12.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function13.png").default}
              />
            </div>
            <div className="function-list-item">
              <img
                className="img"
                src={require("../../../assets/image/os/function14.png").default}
              />
            </div>
          </div>
          <div className="tips">部分功能正在开发中，即将在版本中更新</div>
        </div>
      </div>
    </div>
  );
};

export default Os;
