import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Menu,
  Button,
  Modal,
  message,
  Carousel,
  Collapse,
  Anchor,
} from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import PayDeviceAuth from "../../../components/PayDeviceAuth"; // 购买组件

import { GET_PRICE } from "../../../api/deviceAuth";

const Price = (props) => {
  const {} = props;

  const [userInfo, setUserInfo] = useState(undefined);
  const [intros, setIntors] = useState([
    {
      num: "100+",
      txt: "功能扩展",
      link: "",
    },
    {
      num: "10G",
      txt: "统一云存储",
      link: "",
    },
    {
      num: "App",
      txt: "模块化",
      link: "",
    },
    {
      num: "API",
      txt: "扩展随心",
      link: "",
    },
  ]);

  const [privileges, setPrivileges] = useState([
    {
      title: "Workspace",
      subtitle: "高效办公 便捷协作",
      child: [
        {
          title: "Word",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "Excel",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "看板",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "脑图",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "PPT",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "协作",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "聊天",
          version1: "5账号",
          version2: "5账号",
          version3: "按需",
        },
      ],
    },
    {
      title: "统一云存储",
      subtitle: "云端备份更安心",
      child: [
        {
          title: "设备云备份",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "文件云备份",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "异地云备份",
          version1: false,
          version2: false,
          version3: true,
        },
      ],
    },
    {
      title: "AI相册",
      subtitle: "人、物、景多重识别",
      child: [
        {
          title: "人物识别",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "物品识别",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "景点识别",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "地点识别",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "其他",
          version1: true,
          version2: true,
          version3: true,
        },
      ],
    },
    {
      title: "影视中心",
      subtitle: "电视、网页、移动随心看",
      child: [
        {
          title: "电视投射",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "广告墙",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "移动端播放",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "视频转码",
          version1: true,
          version2: true,
          version3: true,
        },
      ],
    },
    {
      title: "AppCenter",
      subtitle: "海量应用 轻松扩展",
      child: [
        {
          title: "容器（Docker）",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "虚拟化（KVM)",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "应用安装",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "其他",
          version1: true,
          version2: true,
          version3: true,
        },
      ],
    },
    {
      title: "智慧助手",
      subtitle: "永不丢失的创意",
      child: [
        {
          title: "笔记",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "思路",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "语音助手",
          version1: true,
          version2: true,
          version3: true,
        },
      ],
    },
    {
      title: "企业中心",
      subtitle: "办公&客户管理",
      child: [
        {
          title: "企业门户",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "客户管理",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "流程审批",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "合同管理",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "财务管理",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "其他",
          version1: false,
          version2: false,
          version3: true,
        },
      ],
    },
    {
      title: "安全审计",
      subtitle: "对重要数据进行严格保护",
      child: [
        {
          title: "操作日志",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "全局水印",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "访问密码",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "病毒防护",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "隐私加密",
          version1: false,
          version2: false,
          version3: true,
        },
      ],
    },
    {
      title: "个性化服务",
      subtitle: "随时随地灵活扩展",
      child: [
        {
          title: "SDK开发包",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "API接口",
          version1: true,
          version2: true,
          version3: true,
        },
        {
          title: "应用DEMO",
          version1: true,
          version2: true,
          version3: true,
        },
      ],
    },
    {
      title: "集群化管理",
      subtitle: "轻松扩展集群化",
      child: [
        {
          title: "中控管理",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "集群部署",
          version1: false,
          version2: false,
          version3: true,
        },
        {
          title: "热备设置",
          version1: false,
          version2: false,
          version3: true,
        },
      ],
    },
  ]);

  const [cur, setCur] = useState([]);

  const [prices, setPrices] = useState({});

  const [payVisible, setPayVisible] = useState(false);
  const [type, setType] = useState(1);

  const onChange = (val) => {
    setCur(val);
  };

  const transNum = (ind, keyName) => {
    return [
      ...privileges[ind].child.filter((citem) => citem[keyName] !== false),
    ].length;
  };

  const transItem = (ci, keyName) => {
    return (
      (ci[keyName] === true && (
        <img src={require(`../../../assets/image/price/yes.svg`).default} />
      )) ||
      (ci[keyName] === false && (
        <img src={require(`../../../assets/image/price/no.svg`).default} />
      )) ||
      ci[keyName]
    );
  };

  const isLogin = async () => {
    if (!cookie.load("accessToken")) {
      cookie.remove("accessToken", { path: "/" });
      setUserInfo(undefined);
      localStorage.removeItem("userInfo");
      return false;
    }

    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    return true;
  };

  const getPrice = async () => {
    const {
      data: { data, code, msg },
    } = await GET_PRICE();
    if (200 === code) setPrices(data);
  };

  useEffect(() => {
    document.title = "FileStation Manager 价格 - 七朵云 - 存储解决方案服务商";
    isLogin();
    getPrice();
  }, []);

  const toPay = (type) => {
    if (!userInfo) {
      location.href = "/";
      return;
    }
    setType(type);
    setPayVisible(true);
  };

  return (
    <div className="price context">
      <div className="big-cover">
        <div className="title">家庭与中小企业私有云存储解决方案</div>
        <div className="subtitle">
          完全私有化且数据隔离的统一存储管理 便捷客户端管理更随心
        </div>
        <div className="info-cont">
          <div className="user-cont">
            <div className="user">
              <div className="cover">
                <img
                  src={
                    require("../../../assets/image/price/user-cover.svg")
                      .default
                  }
                />
              </div>
              {(userInfo && (
                <div className="info">
                  <div className="nickname">{userInfo?.account}</div>
                </div>
              )) || (
                <div className="info">
                  <div className="nickname">请先登录</div>
                  <div className="info">登录后查看已购买授权</div>
                </div>
              )}
            </div>
            <div className="action">
              {(userInfo && (
                <Button href="/usercenter/device/list" type="primary" block>
                  查看我购买的授权
                </Button>
              )) || (
                <Button href="/login" type="primary" block>
                  立即登录
                </Button>
              )}
            </div>
          </div>
          <div className="intro-cont">
            {intros.map((item, ind) => (
              <div className="intro" key={ind}>
                <div className="info">
                  <div className="num">{item.num}</div>
                  <div className="txt">{item.txt}</div>
                </div>
                <div className="cover">
                  <img
                    src={
                      require(`../../../assets/image/price/intro-${ind}.png`)
                        .default
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Anchor
        affix={false}
        targetOffset={188 + 64}
        onClick={(e, link) => {
          console.log(e, link);
          setCur(Array.from(new Set([...cur, link.href.split("-")[1]])));
        }}
      >
        <div className="privilege">
          <div className="container ">
            <div className="title">FileStation Manager V2 版本功能介绍</div>
            <div className="privilege-list">
              {privileges.map((item, ind) => (
                <Anchor.Link
                  href={`#privileges-${ind}`}
                  key={ind}
                  title={
                    <div className="privilege-list-item">
                      <div className="cover">
                        <img
                          src={
                            require(`../../../assets/image/price/privilege-${ind}.svg`)
                              .default
                          }
                        />
                      </div>
                      <div className="title">{item.title}</div>
                      <div className="subtitle">{item.subtitle}</div>
                      <div className="link">
                        {transNum(ind, "version3")}
                        项功能
                        <RightOutlined className="ml-2" />
                      </div>
                    </div>
                  }
                />
              ))}
            </div>
          </div>
        </div>

        <div className="compared">
          <div className="container ">
            <div className="title">功能对比明细</div>
            <div className="compared-cont">
              <div className="compared-cont-header">
                <div className="version title">
                  <div className="txt">功能权益</div>
                </div>
                <div className="version version1">
                  <div className="txt">家庭版</div>
                  <div className="txt price">
                    ￥<span className="big">{prices?.home?.subPrice}</span>/年
                  </div>
                  <div className="action">
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        toPay(1);
                      }}
                    >
                      购买
                    </Button>
                  </div>
                </div>
                <div className="version version2">
                  <div className="txt">家庭版</div>
                  <div className="txt price">
                    ￥<span className="big">{prices?.home?.price}</span>/终身
                  </div>
                  <div className="action">
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        toPay(2);
                      }}
                    >
                      购买
                    </Button>
                  </div>
                </div>
                <div className="version version3">
                  <div className="txt">企业版</div>
                  <div className="action">
                    <Button type="primary" block>
                      咨询销售
                    </Button>
                  </div>
                </div>
              </div>
              <div className="compared-cont-body">
                <Collapse
                  className="list"
                  activeKey={cur}
                  onChange={onChange}
                  bordered={false}
                >
                  {privileges.map((item, ind) => (
                    <Collapse.Panel
                      id={`privileges-${ind}`}
                      className="item"
                      showArrow={false}
                      key={ind}
                      header={
                        <div
                          className={`head ${
                            cur.findIndex((fi) => fi == ind) >= 0 ? "cur" : ""
                          }`}
                        >
                          <div className="title">
                            <div className="icon">
                              <CaretRightOutlined />
                            </div>
                            <img
                              src={
                                require(`../../../assets/image/price/privilege-${ind}.svg`)
                                  .default
                              }
                            />
                            <span className="txt">{item.title}</span>
                          </div>
                          <div className="version version1">
                            {transNum(ind, "version1")}项
                          </div>
                          <div className="version version2">
                            {transNum(ind, "version2")}项
                          </div>
                          <div className="version version3">
                            {transNum(ind, "version3")}项
                          </div>
                        </div>
                      }
                    >
                      {item.child.map((ci, cind) => (
                        <div className="child" key={cind}>
                          <div className="version title">{ci.title}</div>
                          <div className="version version1">
                            {transItem(ci, "version1")}
                          </div>
                          <div className="version version2">
                            {transItem(ci, "version2")}
                          </div>
                          <div className="version version3">
                            {transItem(ci, "version3")}
                          </div>
                        </div>
                      ))}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            </div>
            <div className="tips">
              <div className="tip title">用户须知：</div>
              <div className="tip mb-1">
                FileStation Manager
                V2版本功能部分功能尚未发布，具体支持时间以发布为准。
              </div>
              <div className="tip mb-1">
                部分应用使用需要单独购买授权，请您在购买前悉知。
              </div>
              <div className="tip mb-1">
                针对家庭版和企业版支持的功能略有差别，如您需要对应的功能建议选择对应的版本。
              </div>
              <div className="tip mb-5">
                企业版收费细则请通过销售人员联系获取报价。
              </div>
            </div>
          </div>
        </div>

        <div className="help">
          <div className="container">
            <div className="title">帮助中心</div>
            <div className="help-list">
              <Collapse bordered={false} expandIconPosition="end">
                <Collapse.Panel
                  header={
                    <div className="title">
                      FileStation Manager 技术支持期限是多久？
                    </div>
                  }
                >
                  <div className="cont">
                    <div className="tip">
                      FSM操作系统根据不同版本所支持的技术支持有所不同，订阅版按照购买授权时长提供，家庭版终身提供3年免费支持，企业订阅版按照购买时长提供支持服务。
                    </div>
                  </div>
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <div className="title">快连服务提供的带宽标准是多少？</div>
                  }
                >
                  <div className="cont">
                    <div className="tip">
                      快连网络服务提供的网络标准是，家庭版提供默认不超过10M的访问速度，企业版提供默认不超过20M访问速度，如带宽满足不了要求需要购买增值服务提速即可。
                    </div>
                  </div>
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <div className="title">快连是否可以绑定自己的域名？</div>
                  }
                >
                  <div className="cont">
                    <div className="tip">
                      目前快连暂未开放绑定自有域名，需要绑定域名的用户可以等后续的官网通知
                    </div>
                  </div>
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <div className="title">
                      终身版我的服务时间超过了，不想购买服务了，是否可以继续免费升级？
                    </div>
                  }
                >
                  <div className="cont">
                    <div className="tip">
                      目前对于服务期已过的授权，我们依然提供免费升级服务，如后续改变运营策略我们会在官网提前公告3个月告知。
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </Anchor>

      <PayDeviceAuth
        visible={payVisible}
        setVisible={setPayVisible}
        version={1}
        type={type}
      />
    </div>
  );
};

export default Price;
