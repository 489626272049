import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Avatar } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Overview = (props) => {
  const {} = props;
  const [userInfo, setUserInfo] = useState();

  const contentStyle = {
    height: "460px",
    color: "#fff",
    lineHeight: "460px",
    textAlign: "center",
    background: "#364d79",
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    } else {
      setUserInfo({});
    }
  }, [localStorage.getItem("userInfo")]);

  useEffect(() => {
    document.title = "用户中心 - 账号概览";
  }, []);

  return (
    <div className="overview context">
      <div className="userinfo">
        <div className="main">
          <div className="img-head">
            <Avatar
              src={require("../../../assets/image/icon (3).png").default}
              size={80}
            />
          </div>
          <div className="intro">
            <div className="nickname">{userInfo?.nickname || "-"}</div>
            <div className="tips">欢迎使用FileStation账户</div>
          </div>
        </div>
        <div className="info">
          <Row gutter={30}>
            <Col span={16}>
              <div className="item">
                <div className="label">会员卡号</div>
                <div className="text blod">{userInfo?.vip_number || "-"}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">
                  {(userInfo?.agent_id && "代理商") || "会员"}级别
                </div>
                <div className="text blod">
                  {(userInfo?.agent_id && userInfo?.agent?.name) ||
                    userInfo?.level?.name ||
                    "-"}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">电子邮箱</div>
                <div className="text">{userInfo?.email || "未设置"}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">手机号</div>
                <div className="text">{userInfo?.mobile || "未设置"}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">用户类型</div>
                <div className="text">
                  {(userInfo?.type &&
                    ((userInfo?.type == 1 && "普通用户") ||
                      (userInfo?.type == 2 && "代理商"))) ||
                    "-"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="orther">
          <Row gutter={30}>
            <Col span={8}>
              <div className="item">
                <div className="label">积分</div>
                <div className="text blod">{userInfo?.integral}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">保证金</div>
                <div className="text blod">{userInfo?.bond || "-"}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">奖励余额</div>
                <div className="text blod">{userInfo?.balance || "-"}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="item">
                <div className="label">用户状态</div>
                <div className="text">
                  {(userInfo?.state == 1 && "正常") || "禁用"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="device">
        <div className="left">
          <div className="title">设备</div>
          <div className="tip">
            管理您的产品，并检查快连QuickLink设置和许可证
          </div>
        </div>
        <div className="right">
          <div className="title">立即添加设备</div>
          <div className="tip">
            您可以在此处查看QuickLink、DDNS服务、许可证、保修等的状态
          </div>
          <div className="action">
            <Link to="/usercenter/device/list">
              <Button icon={<PlusOutlined />}>添加设备</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
