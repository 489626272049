import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取优惠券列表
 * @param {Object} parm 请求参数
 * @returns
 */
export const GET_COUPON_LIST = async (parm) =>
  await instance.post(`${base}/coupon/list`, parm);

/**
 * 获取用户优惠券列表
 * @param {Object} parm 请求参数
 * @returns
 */
export const GET_USER_COUPON_LIST = async (parm) =>
  await instance.post(`${base}/user/coupon/list`, parm);

/**
 * 获取用户可用优惠券列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.category_id 商品分类id
 * @param {Number} parm.goods_id 商品id
 * @param {Number} parm.price 商品价格
 * @param {Number} parm.is_auth 是否为购买授权
 * @returns
 */
export const GET_USER_USE_COUPON_LIST = async (parm) =>
  await instance.post(`${base}/user/coupon/uselist`, parm);

/**
 * 领取优惠券
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 优惠券ID
 * @returns
 */
export const RECEIVE_COUPON = async (parm) =>
  await instance.post(`${base}/user/coupon/receive`, parm);
