import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Download = (props) => {
  const {} = props;

  const [navbar, setNavbar] = useState([
    {
      label: "引导程序",
      key: "bootstrap",
    },
    {
      label: "镜像文件",
      key: "iso",
    },
    {
      label: "移动端",
      key: "mob",
    },
    {
      label: "桌面端",
      key: "pc",
    },
    {
      label: "电视端",
      key: "video",
    },
    {
      label: "驱动程序",
      key: "driver",
    },
  ]);
  const [menuKey, setMenuKey] = useState("bootstrap");

  const [dlist, setDlist] = useState([
    {
      title: "通用X86平台",
      href: "http://mirror.qiduo.net:8599/x86_64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "LoongArch64",
      href: "http://mirror.qiduo.net:8599/LoongArch64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "ARM 64",
      href: "http://mirror.qiduo.net:8599/Arm/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "D8",
      href: "http://mirror.qiduo.net:8599/x86_64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "MINI 5",
      href: "http://mirror.qiduo.net:8599/x86_64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "D12",
      href: "http://mirror.qiduo.net:8599/x86_64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "D4",
      href: "http://mirror.qiduo.net:8599/x86_64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "D2",
      href: "http://mirror.qiduo.net:8599/x86_64/bootloader/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "bootstrap",
    },
    {
      title: "MINI5",
      href: "http://mirror.qiduo.net:8599/x86_64/img/ ",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "iso",
    },
    {
      title: "D12",
      href: "http://mirror.qiduo.net:8599/x86_64/img/ ",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "iso",
    },
    {
      title: "D2",
      href: "http://mirror.qiduo.net:8599/x86_64/img/ ",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "仅适用于基于RK3568处理器的M2 设备使用",
      type: "iso",
    },
    {
      title: "HS12",
      href: "http://mirror.qiduo.net:8599/x86_64/img/ ",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "iso",
    },
    {
      title: "x86",
      href: "http://mirror.qiduo.net:8599/x86_64/img/ ",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "iso",
    },
    {
      title: "LoongArch64",
      href: "http://mirror.qiduo.net:8599/LoongArch64/img/",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "目前经过测试仅支持3A5000+7A2000方案，3A6000处理器暂未进行测试",
      type: "iso",
    },
    {
      title: "IOS客户端",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "mob",
    },
    {
      title: "Andriod 客户端",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "mob",
    },
    {
      title: "HarmonyOS客户端",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "mob",
    },
    {
      title: "Windows 同步盘",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "pc",
    },
    {
      title: "Mac 同步盘（Apple 处理器）",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "pc",
    },
    {
      title: "Mac 同步盘（Intel版）",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "pc",
    },
    {
      title: "Linux 同步盘",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "pc",
    },
    {
      title: "Andriod TV 版本",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "video",
    },
    {
      title: "HarmonyOS TV版本",
      href: "#",
      version: "1.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "video",
    },
    {
      title: "Intel i226/225 网卡驱动",
      href: "#",
      version: "2.0.0",
      time: "2024年4月1日",
      tip: "",
      type: "driver",
    },
  ]);

  useEffect(() => {
    document.title =
      "FileStation Manager 下载中心 - 七朵云 - 存储解决方案服务商";
  }, []);

  const onMenuClick = (e) => {
    console.log("click ", e);
    setMenuKey(e.key);
  };
  return (
    <div className="download context">
      {/* <div className="carousel">
        <Carousel autoplay afterChange={onChange}>
          <div className="cover-img">
            <img
              src={require("../../../assets/image/banner/banner1.jpg").default}
              style={{ width: "100%" }}
            />
          </div>
        </Carousel>
      </div> */}
      <div className="banner"></div>
      <div className="navbar">
        <Menu
          className="container"
          mode="horizontal"
          items={navbar}
          selectedKeys={[menuKey]}
          onClick={onMenuClick}
        />
      </div>
      <div className="list">
        <div className="container">
          <div className="d-list">
            {dlist.map(
              (item, ind) =>
                (item.type == menuKey && (
                  <div className="d-list-item" key={ind}>
                    <div className="cover">
                      <img
                        className="img"
                        src={
                          require("../../../assets/image/download-cover-pc.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="title">{item.title}</div>
                    <div className="btn">
                      <Button href={item.href} type="primary" target="_blank">
                        点击下载
                      </Button>
                    </div>
                    <div className="version">版本号：{item.version}</div>
                    <div className="time">发布日期：{item.time}</div>
                    <div className="tip">{item.tip}</div>
                  </div>
                )) ||
                ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
