import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Col, Row, Spin, Modal } from "antd";
import {
  MobileOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./login.less";
import { LOGIN } from "../../api/login";
import cookie from "react-cookies";
import uitls from "../../assets/js/public";
import { message } from "antd";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    document.title = "用户登录";

    if (!!cookie.load("accessToken")) {
      this.props.history.push("/");
    }
  };

  login = async (values) => {
    this.setState({ loading: true });
    values = Object.assign(values);
    let {
      data: { code, data, msg },
    } = await LOGIN(values);
    console.log(code);
    // console.log(cookie.load('ar').redirectUri)
    if (code === 200) {
      message.success("登录成功");
      cookie.save("accessToken", data?.token);
      // localStorage.setItem("userInfo", JSON.stringify(data));
      this.props.history.push("/");
      return;
    } else {
      message.error(msg);
    }

    // message.error(res.Message);
    this.setState({ loading: false });

    // // test
    //   message.success("登录成功");
    //   this.props.history.push("/overview");
    //   return;
  };

  render() {
    return (
      <div className="login-cont">
        <div className="copyright">
          <p>Copyright © 2023 宿州七朵信息技术有限公司 
            <a href="https://beian.miit.gov.cn/" target="_blank">
              沪ICP备2020033741号-1
            </a></p>
        </div>
        <div className="login-sign">
          <div className="title">
            {/* <h1>登录</h1> */}
            <p>
              {/* 没有账号？ */}
              <Link to="/">
                <ArrowLeftOutlined className="mr-1" />
                返回首页
              </Link>
            </p>
          </div>
          <Form
            className="login-form"
            onFinish={this.login}
            name="login"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                size="large"
                allowClear
                prefix={
                  <MobileOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                placeholder="请输入手机号"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                立即登录
              </Button>
              <div className="agreement my-3">
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Checkbox>
                    我已阅读并接受<a>《服务协议》</a>和<a>《隐私协议》</a>
                  </Checkbox>
                </Form.Item>
              </div>
              <Link to="/register">
                <Button
                  type="primary"
                  className="login-form-button"
                  size="large"
                  style={{ fontSize: "14px", borderRadius: "5px" }}
                  block
                >
                  立即注册
                </Button>
              </Link>
              <Link to="/forget">
                <Button
                  type="link"
                  className="login-form-button"
                  size="large"
                  style={{ fontSize: "14px", borderRadius: "5px" }}
                  block
                >
                  忘记密码？
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
