import { message, notification } from "antd";
import _ from "lodash";
import qs from "qs";
import moment from "moment";

/**
 * 财务类型
 * @param {Number} state 状态值
 * @returns
 */
export const ENM_FINANCIAL_TYPE = (state) => {
  return (
    (state == 0 && "-") ||
    (state == 1 && "积分") ||
    (state == 2 && "保证金") ||
    (state == 3 && "奖励余额") ||
    (state == 4 && "线上支付") ||
    "-"
  );
};

/**
 * 财务支付渠道
 * @param {Number} state 状态值
 * @returns
 */
export const ENM_FINANCIAL_CHANNEL = (state) => {
  return (
    (state === 0 && "-") ||
    (state === 1 && "余额") ||
    (state === 2 && "支付宝") ||
    (state === 3 && "微信") ||
    (state === 4 && "积分") ||
    (state === 4 && "混合支付") ||
    "-"
  );
};

/**
 * 财务操作类型
 * @param {Number} state 状态值
 * @returns
 */
export const ENM_FINANCIAL_ACTIONS_TYPE = (state) => {
  return (
    (state == 0 && "-") ||
    (state == 1 && "增加") ||
    (state == 2 && "减少") ||
    "-"
  );
};

/**
 * 订单状态
 * @param {Number} state 状态值
 * @returns
 */
export const ENM_ORDER_STATE = (state) => {
  return (
    (state === 0 && "已关闭") ||
    (state === 1 && "可使用") ||
    (state === 2 && "已发货") ||
    (state === 3 && "已结束") ||
    ""
  );
};

/**
 * 支付状态
 * @param {Number} state 状态值
 * @param {Number} pay_text 支付成功值返回
 * @returns
 */
export const ENM_PAY_STATE = (state, pay_text) => {
  return (
    (state === 0 && "未支付") ||
    (state === 1 && !!pay_text && pay_text) ||
    (state === 1 && "已支付") ||
    "-"
  );
};

/**
 * 商品订单对应支付方式
 * @param {Number} state 状态值
 * @returns
 */
export const ENM_ORDER_PAY_TYPE = (state) => {
  return (
    (state === 0 && "未支付") ||
    (state === 1 && "余额支付") ||
    (state === 2 && "微信支付") ||
    (state === 3 && "支付宝支付") ||
    (state === 4 && "组合支付") ||
    "-"
  );
};
