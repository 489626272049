import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Spin,
  Modal,
  Checkbox,
  message,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  MobileOutlined,
  MailOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./login.less";
import { CHECK, GET_SMS } from "../../api/login";
import cookie from "react-cookies";
import uitls from "../../assets/js/public";
import qs from "qs";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
      sendDisabled: false,
      time: 120,
      formVals: {},
      info: {},
    };
  }

  componentDidMount = () => {
    document.title = "登录信息检测";
    this.info = qs.parse(location.search.split("?")[1]);
    console.log(this.info);
    this.checkinfo();
  };

  count = () => {
    let { time } = this.state;
    let siv = setInterval(() => {
      this.setState({ time: time-- }, () => {
        if (time <= -1) {
          clearInterval(siv); //倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          this.setState({ sendDisabled: false, time: 120 });
        }
      });
    }, 1000);
  };

  checkinfo = async () => {
    const {
      data: { code, data, msg },
    } = await CHECK({
      ...this.info,
    });

    if (1000 == code) {
      message.info("请输入手机号信息完成账号绑定");
      return;
    }
    if (200 == code) {
      cookie.save("accessToken", data?.token);
      // localStorage.setItem("userInfo", JSON.stringify(data));
      this.props.history.push("/");
    }

    console.log(code, data, msg);
  };

  check = async (values) => {
    this.setState({ loading: true });
    // values.code_id = localStorage.getItem("codeid");
    const {
      data: { code, data, msg },
    } = await CHECK({
      ...this.info,
      ...values,
    });
    console.log(code, data, msg);
    if (200 == code) {
      message.success("绑定成功正在跳转...");
      localStorage.removeItem("codeid");
      cookie.save("accessToken", data?.token);
      this.props.history.push("/");
    } else {
      message.error(msg);
    }
    this.setState({ loading: false });
  };

  render() {
    const { formVals } = this.state;
    return (
      <div
        className="login-cont"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          position: "relative",
          minHeight: "100%",
        }}
      >
        <div></div>
        <div className="login-register check">
          <div className="title mb-5">
            <h1>请验证信息</h1>
          </div>
          <Form
            className="login-form"
            onFinish={this.check}
            name="normal_login"
            initialValues={{ remember: true }}
            onValuesChange={(vals) => {
              let formVals = { ...this.state.formVals, ...vals };
              this.setState({ formVals: formVals });
            }}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                size="large"
                allowClear
                prefix={
                  <MobileOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                placeholder="请输入手机号"
              />
            </Form.Item>

            <Form.Item>
              <Row gutter={8}>
                <Col span={14}>
                  <Form.Item
                    name="smscode"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "请输入短信码",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      allowClear
                      prefix={
                        <MailOutlined
                          style={{
                            color: "rgba(0,0,0,.25)",
                            fontSize: "18px",
                          }}
                        />
                      }
                      placeholder="请输入短信码"
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Button
                    size="large"
                    block
                    disabled={this.state.sendDisabled}
                    onClick={async () => {
                      if (!uitls.phone(formVals.mobile)) {
                        message.error("请输入正确的手机号码");
                        return;
                      }

                      if (this.state.time > 0) {
                        this.setState({ sendDisabled: true });
                        let res = await GET_SMS({
                          mobile: formVals.mobile,
                          type: "check",
                        });
                        console.log(res);
                        if (200 == res.status && 200 == res.data.code) {
                          message.success("短信发送成功，请注意查收");
                          this.count();
                          localStorage.setItem(
                            "codeid",
                            res.data.data.chuanglan.result.msgId
                          );
                        } else {
                          this.setState({ sendDisabled: false, time: 120 });
                        }
                      }
                    }}
                  >
                    {this.state.sendDisabled
                      ? this.state.time + "秒"
                      : "获取短信码"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                验证信息
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          className="copyright"
          style={{
            position: "static",
            height: "auto",
            margin: 0,
          }}
        >
          <p>
            Copyright © 2023 宿州七朵信息技术有限公司
            <a href="https://beian.miit.gov.cn/" target="_blank">
              沪ICP备2020033741号-1
            </a>
          </p>
        </div>
      </div>
    );
  }
}
