import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel, Anchor } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";

import {} from "../../../common/utils";
import { staticFilePath } from "../../../api/config";

import { GET_CITY } from "../../../api/common";
import { GET_GOODS_DETAIL } from "../../../api/goods";

const Detail = (props) => {
  const {} = props;

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [detail, setDetail] = useState({});
  const [sku, setSku] = useState([]);
  const [showSku, setShowSku] = useState([]);

  const toolRef = useRef();

  useEffect(() => {
    getDetail(params.id);
  }, [params.id]);

  const getDetail = async (id) => {
    const {
      data: { data, code, msg },
    } = await GET_GOODS_DETAIL({
      id,
    });
    if (code == 200) {
      // console.log(res.data)
      document.title = data.name;

      setDetail(data);
      data.sku.map((item) => {
        // console.log(JSON.parse(item.sku));
        setSku([
          ...sku,
          {
            ...JSON.parse(item.sku),
          },
        ]);
      });
      // console.log(JSON.parse(data.sku_json));
      let skus = JSON.parse(data.sku_json);
      let showSkus = [];
      skus.map((item) => {
        // console.log(item);

        for (const key in item) {
          const hasIndex = showSkus.findIndex((fitem) => fitem.label == key);
          if (hasIndex < 0) {
            showSkus.push({
              label: key,
              value: [],
            });
          }
        }
        Object.values(item).map((it, ind) => {
          const hasValue = showSkus[ind].value.findIndex(
            (vitem) => vitem == it
          );
          if (hasValue >= 0) {
            return;
          }
          showSkus[ind].value.push(it);
        });
      });
      setShowSku(showSkus);
    }
  };

  useEffect(() => {
    if (sku.length) {
    }
  }, [sku]);

  useEffect(() => {
    // console.log(showSku);
  }, [showSku]);

  useEffect(() => {
    document.title = detail.name;
    // console.log(detail)
  }, []);

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };

  return (
    <div className="product detail">
      <div className="cover">
        {/* <img src={detail.cover} /> */}
        <Carousel autoplay afterChange={onChange}>
          {detail?.images &&
            detail.images.split(",").map((item, index) => (
              <div className="cover-img" key={index}>
                <img src={item} style={{ width: "100%" }} />
              </div>
            ))}
        </Carousel>
      </div>
      {/* {console.log(toolRef)} */}
      <div className="tool-bar" ref={toolRef}>
        <div className="container">
          <div className="characteristic">
            <div className="title">{detail.name}</div>
            <div className="intro">{detail.intro}</div>
          </div>
          <div className="tool">
            <Anchor targetOffset={64 + 184}>
              {/* <Anchor.Link href="#sku" title="规格" /> */}
              <Anchor.Link href="#details" title="特色" />
              <Anchor.Link href="#intro" title="规格" />
            </Anchor>
            <div className="buy">
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/product/buy/${detail.id}`);
                }}
              >
                去购买
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="sku-context" id="sku">
        {showSku.length &&
          showSku.map((item, index) => (
            <div className="sku" key={index}>
              <div className="label">{item.label}</div>
              <div className="value">
                {item.value.length &&
                  item.value.map((it, ind) => (
                    <div className="item" key={ind}>
                      {it}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div> */}
      <div className="container">
        <div
          id="details"
          className="detail-context"
          dangerouslySetInnerHTML={{ __html: detail.pc_detail }}
        ></div>
      </div>
      <div className="container">
        {(detail?.sku_intro && (
          <div className="sku_intro" id="intro">
            {JSON.parse(detail.sku_intro).map((item, index) => (
              <div className="item" key={index}>
                <div className="label">{item.label}</div>
                <div className="context">
                  <div className="value">{item.value}</div>
                  {(item.tips && <div className="tip">{item.tips}</div>) || ""}
                </div>
              </div>
            ))}
          </div>
        )) ||
          ""}
      </div>
    </div>
  );
};

export default Detail;
