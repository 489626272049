import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Modal,
  message,
  Badge,
  Form,
  Input,
  Pagination,
  Empty,
  Typography,
  Popconfirm,
  Collapse,
  Select,
} from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  HddFilled,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { QRCodeCanvas } from "qrcode.react";
import {
  GET_DEVICE_AUTH_LIST,
  GET_DEVICE_REG_LIST,
  BIND_DEVICE_AUTH,
  GET_DEVICE_AUTH_DETAIL,
  RESET_DEVICE_AUTH,
} from "../../../api/deviceAuth";

const CreateForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});

  const [auths, setAuths] = useState([]);

  const getAuths = async () => {
    const {
      data: { code, data, msg },
    } = await GET_DEVICE_AUTH_LIST({
      pageSize: 999999,
      pageNo: 1,
      total: 0,
    });
    if (200 === code) {
      setAuths(data.resource);
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getAuths();
      if (info) {
        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-create"
      centered
    >
      <div className="title">
        <h1>激活系统</h1>
      </div>
      <div className="content">
        <Row gutter={30}>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                ...info,
              }}
              name="form_in_modal"
              onValuesChange={(vals, allVals) => {
                setFormVals(allVals);
              }}
            >
              <div>
                <Form.Item name="sn" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="secret">
                  <Select
                    placeholder="请选择授权"
                    options={[
                      ...auths.map((item) => ({
                        label: `${item.secret}/${item.version}/${item.type}/${item.end_at}`,
                        value: item.secret,
                      })),
                    ]}
                  />
                </Form.Item>
              </div>
            </Form>
            <div className="mt-3">
              <Button
                type="primary"
                size="large"
                className="py-3 px-5"
                style={{ height: "auto", lineHeight: "1" }}
                onClick={() => {
                  let params = { ...form.getFieldsValue() };

                  console.log(params);
                  onCreate(params);
                }}
              >
                确认激活
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const Device = (props) => {
  const {} = props;
  const [userInfo, setUserInfo] = useState();
  const [visibleInfo, setVisibleInfo] = useState({});
  const [visibleBind, setVisibleBind] = useState(false);

  const [price, setPrice] = useState({});
  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const qrcodeTimeRef = useRef();

  const [list, setList] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    } else {
      setUserInfo({});
    }
  }, [localStorage.getItem("userInfo")]);

  // 变更
  const onChange = (keys) => {
    setOpenKeys(keys);
  };

  // 提交绑定操作
  const onBind = async (values) => {
    console.log(values);
    const {
      data: { code, data, msg },
    } = await BIND_DEVICE_AUTH(values);

    if (code == 200) {
      message.success(msg);
      setVisibleInfo({});
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleBind(false);
  };

  // 重置授权
  const onReset = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await RESET_DEVICE_AUTH(values);
    if (code == 200) {
      message.success("重置授权成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_DEVICE_REG_LIST({ ...paginations });
    if (code == 200) {
      setList(data.resource);
      setOpenKeys([...data.resource.map((item, ind) => ind)]);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };

  // 分页操作
  const onPageChange = (page) => {
    setPagination({
      ...pagination,
      pageNo: page,
    });
    getList({
      ...pagination,
      pageNo: page,
    });
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  useEffect(() => {
    document.title = "用户中心 - 设备授权管理";
  }, []);

  return (
    <div className="device context">
      {/* <div className="actions">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setVisibleBind(true);
          }}
        >
          创建设备
        </Button>
      </div> */}
      <div className="device-list">
        <Collapse
          activeKey={openKeys}
          onChange={onChange}
          expandIconPosition="end"
        >
          {list.map((item, ind) => (
            <Collapse.Panel
              key={ind}
              header={
                <div className="device-list-item-header">
                  <div className="name">{item.hostname || "设备名称"}</div>
                  <div className="sub">{item.board}</div>
                </div>
              }
            >
              <div className="device-list-item-body">
                <div className="device-list-item-wapper">
                  <div className="info-cont">
                    <div className="cover">
                      <img
                        src={
                          require("../../../assets/image/usercent/device/device-cover.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="info">
                      <div className="name">{item.hostname || "设备名称"}</div>
                      <div className="sub">{item.board}</div>
                      <div className="sub">{item.sn}</div>
                    </div>
                  </div>
                  <div className="info-action">
                    <div className="status">
                      {(item?.auth && (
                        <Button type="primary">{item?.auth?.version}</Button>
                      )) || (
                        <Button type="primary" danger>
                          未激活
                        </Button>
                      )}
                    </div>

                    {item?.auth && (
                      <div className="num">
                        {item?.auth?.member_num || "无限制"}
                      </div>
                    )}

                    {item?.auth?.end_at && (
                      <div className="end">{item?.auth?.end_at}</div>
                    )}
                  </div>
                </div>
                <div className="device-list-item-footer">
                  <div className="secret">
                    {item?.auth?.secret || (
                      <div>
                        未激活？
                        <a
                          onClick={() => {
                            setVisibleInfo(item);
                            setVisibleBind(true);
                          }}
                        >
                          点击绑定
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="action">
                    {item?.auth && (
                      <Popconfirm
                        title="该操作将解绑设备授权信息，是否继续？"
                        onConfirm={() => {
                          onReset({
                            id: item.id,
                          });
                        }}
                        onCancel={() => {}}
                        okText="确定"
                        cancelText="取消"
                      >
                        <Button className="mr-3" type="primary" danger>
                          解绑
                        </Button>
                      </Popconfirm>
                    )}
                    <Button type="primary">管理</Button>
                  </div>
                </div>
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
        {/* {list.map((item, index) => (
          <div className="device-list-item" key={index}>
            <div className="item-body">
              <div className="item icon">
                <HddFilled />
              </div>
              <div className="item name">
                <div className="label">设备名称</div>
                <div className="text" title={item.name}>
                  {item.name}
                </div>
              </div>
              <div className="item date">
                <div className="label">创建时间</div>
                <div className="text">{item.created_at}</div>
              </div>
              <div className="item state">
                <div className="label">设备状态</div>
                <div className="text">
                  <Badge
                    status={
                      (item.state == 0 && "default") ||
                      (item.state == 1 && "success") ||
                      "error"
                    }
                    text={
                      (item.state == 0 && "未授权") ||
                      (item.state == 1 && "已授权") ||
                      "已锁定"
                    }
                  />
                </div>
              </div>
              <div className="item quicklink text-center">
                <div className="label">快连URL</div>
                <div className="text">
                  <Button
                    type="primary"
                    size="small"
                    href={item.quicklink_url}
                    target="_blank"
                  >
                    使用快连访问
                  </Button>
                </div>
              </div>
              <div className="item actions">
                <div className="label">操作</div>
                <div className="text">
                  <Popconfirm
                    title="设备授权信息将会清空，是否继续？"
                    onConfirm={() => {
                      onReset({
                        id: item.id,
                      });
                    }}
                    onCancel={() => {}}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button className="mr-3" type="link" size="small">
                      重置授权
                    </Button>
                  </Popconfirm>
                  <Button className="mr-3" type="link" size="small">
                    查看设备
                  </Button>
                  {(item.state != 1 && (
                    <Button
                      className="mr-3"
                      type="link"
                      size="small"
                      onClick={() => {
                        onBuy({
                          id: item.id,
                        });
                      }}
                    >
                      购买
                    </Button>
                  )) ||
                    ""}
                  <Popconfirm
                    title="删除设备后将无法找回，是否继续？"
                    onConfirm={() => {
                      onRemove({
                        id: item.id,
                      });
                    }}
                    onCancel={() => {}}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button className="mr-3" type="link" size="small" danger>
                      删除
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
            <div className="item-foot">
              <div className="item">
                <div className="label">序列号</div>
                <div className="text">
                  <Typography.Paragraph copyable>
                    {item.uuid}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="item">
                <div className="label">特征码</div>
                <div className="text">
                  {(item?.authorize?.signature_code && (
                    <Typography.Paragraph copyable>
                      {item?.authorize?.signature_code}
                    </Typography.Paragraph>
                  )) ||
                    "-"}
                </div>
              </div>
              <div className="item">
                <div className="label">机器码</div>
                <div className="text">
                  {(item?.authorize?.machine_code && (
                    <Typography.Paragraph copyable>
                      {item?.authorize?.machine_code}
                    </Typography.Paragraph>
                  )) ||
                    "-"}
                </div>
              </div>
              <div className="item">
                <div className="label">状态</div>
                <div className="text">
                  {(item?.authorize?.signature_code &&
                    item?.authorize?.machine_code &&
                    "已绑定") ||
                    "未绑定"}
                </div>
              </div>
            </div>
          </div>
        ))} */}
        {!list.length && (
          <div className="empty py-5">
            <Empty />
          </div>
        )}
      </div>
      {!!list.length && (
        <div className="pagination text-right">
          <Pagination
            current={pagination.pageNo}
            total={pagination.total}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </div>
      )}

      {/* 创建 */}
      {visibleBind && (
        <CreateForm
          visible={visibleBind}
          info={visibleInfo}
          onCreate={onBind}
          onCancel={() => {
            setVisibleBind(false);
            setVisibleInfo({});
          }}
        />
      )}

      {(qrcodeVisible && (
        <Modal
          open={qrcodeVisible}
          width={600}
          closable={false}
          footer={[
            <div
              className="price-info text-left"
              style={{
                position: "absolute",
                left: "15px",
                marginTop: "-7px",
              }}
            >
              <div
                className="price"
                style={{ display: price?.event_price > 0 ? "none" : "block" }}
              >
                <div>
                  <span className="label">实付金额：</span>
                  <span className="price">授权价格</span>
                  <span className="space mx-1">-</span>
                  <span className="dedcut">优惠金额</span>
                  <span className="space mx-1">=</span>
                  <span
                    className="total"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    实付金额
                  </span>
                </div>
                <div>
                  <span className="label">实付金额：</span>
                  <span className="price">{price?.price || "获取中"}</span>
                  <span className="space mx-1">-</span>
                  <span className="dedcut">
                    {price?.dedcut_amount || "获取中"}
                  </span>
                  <span className="space mx-1">=</span>
                  <span
                    className="total"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {(price?.price &&
                      price?.dedcut_amount &&
                      price?.price - price?.dedcut_amount) ||
                      "获取中"}
                  </span>
                </div>
              </div>
              <div
                className="eventPrice"
                style={{ display: price?.event_price > 0 ? "block" : "none" }}
              >
                <span>活动价格：</span>
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: 24,
                    marginRight: 10,
                  }}
                >
                  ￥{price?.event_price}
                </span>
                <span
                  style={{
                    color: "#999",
                    textDecoration: "line-through",
                  }}
                >
                  ￥{price?.price - price?.dedcut_amount}
                </span>
              </div>
            </div>,
            <Button
              onClick={() => {
                closeQrcode();
              }}
            >
              未完成支付
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                closeQrcode();
              }}
            >
              已完成支付
            </Button>,
          ]}
        >
          <div className="tips mb-5">
            请确认您购买授权后将不支持退款，支付后相关款项会从微信中扣除，如果您不确定是否有强烈的需求，请您先体验30天后转为正式授权即可。
          </div>
          <div className="text-center">
            <QRCodeCanvas width={300} value={qrcodeUrl} />
          </div>
        </Modal>
      )) ||
        ""}
    </div>
  );
};

export default Device;
