import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Home = (props) => {
  const {} = props;

  const [notifyVisible, setNotifyVisible] = useState(false);

  const [intros, setIntros] = useState([
    {
      title: "基于Linux打造",
      subtitle: "内核版本 6.X",
    },
    {
      title: "开放式扩展",
      subtitle: "支持自主扩展",
    },
    {
      title: "统一身份认证",
      subtitle: "1个账号，快速打通",
    },
    {
      title: "集群化",
      subtitle: "本地异地集群化支持",
    },
    {
      title: "5K",
      subtitle: "支持高达5000人同时协作办公",
    },
    {
      title: "可视化操作",
      subtitle: "和Windows一样的操作方式",
    },
    {
      title: "访问安全审计",
      subtitle: "每个操作都会记录并审核",
    },
    {
      title: "Software RAID & ZFS",
      subtitle: "双存储阵列模式可选，安全更可靠",
    },
  ]);

  const [navs, setNavs] = useState([
    "家庭存储",
    "办公协同",
    "灾难备份",
    "教育科研",
    "网络监控",
    "安全审计",
  ]);
  const [curNav, setCurNav] = useState(0);

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  useEffect(() => {
    document.title = "七朵云 - 存储解决方案服务商";

    if (!localStorage.getItem("isShowNotify")) {
      setNotifyVisible(true);
      localStorage.setItem("isShowNotify", 1);
    }
  }, []);
  return (
    <div className="home context">
      <div className="carousel">
        <Carousel autoplay afterChange={onChange}>
          <div className="banner-item">
            <div className="item">
              {/* <div className="cont">
                <div className="title">
                  <span className="mr-2">FileStation Manager </span>
                  <small>®</small>
                </div>
                <div className="subtitle">留住美好回忆 尽享连接便利</div>
                <div className="btns">
                  <Button href="//www.qiduo.net/download" target="_blank">
                    体验FSM操作系统
                  </Button>
                </div>
              </div>
              <div className="cover-img">
                <img
                  src={require("../../../assets/image/banner.jpg").default}
                />
              </div> */}
              <div className="title">
                <img
                  src={
                    require("../../../assets/image/home/FileStation Manager.svg")
                      .default
                  }
                />
              </div>
              <div className="subtitle">私有云管理的全新选择</div>
              <img
                src={require("../../../assets/image/home/banner.svg").default}
              />
            </div>
          </div>
        </Carousel>
      </div>
      <div className="container">
        <div className="intro-list">
          {intros.map((item, ind) => (
            <div className="intro-list-item" key={ind}>
              <div className="icon">
                <img
                  src={
                    require(`../../../assets/image/home/intro/${ind + 1}.svg`)
                      .default
                  }
                />
              </div>
              <div className="title">{item.title}</div>
              <div className="subtitle">{item.subtitle}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="title">解决方案</div>
        <div className="solution-cont">
          <div className="navs">
            {navs.map((item, ind) => (
              <div
                className={`navs-item ${ind === curNav && "cur"}`}
                key={ind}
                onClick={() => {
                  setCurNav(ind);
                }}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="item-cont">
            <Carousel></Carousel>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          width: "100%",
          height: 347,
          backgroundImage: `url(${
            require("../../../assets/image/home/bg.jpg").default
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="desc-cont">
          <div className="title">
            <span className="mr-3">生态融合</span>
            <span>联合创新</span>
          </div>
          <div className="subtitle">
            全渠道安全监控，私有化管理，共建数据管理新方式
          </div>
          <div className="btns">
            <Button className="mr-4">了解生态合作计划</Button>
            <Button>查询硬件支持列表</Button>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="download-list">
          <div className="download-list-item">
            <div className="title">U盘引导下载</div>
            <Button
              href="https://www.123pan.com/s/ChnUVv-p8653.html"
              target="_blank"
              icon={
                <img
                  src={require("../../../assets/image/download.png").default}
                />
              }
              type="primary"
            >
              x86 引导
            </Button>
            <div className="tip">更新时间：2024年1月16日</div>
          </div>
          <div className="download-list-item">
            <div className="title">x86 通用固件下载</div>
            <Button
              href="https://www.123pan.com/s/ChnUVv-f8653.html"
              target="_blank"
              icon={
                <img
                  src={require("../../../assets/image/download.png").default}
                />
              }
              type="primary"
            >
              点击下载
            </Button>
            <div className="tip">更新时间：2024年1月16日</div>
          </div>
          <div className="download-list-item">
            <div className="title">LoongArch 固件下载</div>
            <Button
              href="https://www.123pan.com/s/ChnUVv-a8653.html "
              target="_blank"
              icon={
                <img
                  src={require("../../../assets/image/download.png").default}
                />
              }
              type="primary"
            >
              即将开放
            </Button>
            <div className="tip">更新时间：2024年1月8日</div>
          </div>
          <div className="download-list-item">
            <div className="title">ARM 固件下载</div>
            <Button
              href="https://www.123pan.com/s/ChnUVv-B8653.html"
              target="_blank"
              icon={
                <img
                  src={require("../../../assets/image/download.png").default}
                />
              }
              type="primary"
            >
              即将开放
            </Button>
            <div className="tip">更新时间：2024年1月8日</div>
          </div>
          <div className="download-list-item">
            <div className="title">MINI5 固件下载</div>
            <Button
              href="https://www.123pan.com/s/ChnUVv-J8653.html"
              target="_blank"
              icon={
                <img
                  src={require("../../../assets/image/download.png").default}
                />
              }
              type="primary"
            >
              即将开放
            </Button>
            <div className="tip">更新时间：2024年1月8日</div>
          </div>
          <div className="download-list-item">
            <div className="title">N100 固件下载</div>
            <Button
              href="https://www.123pan.com/s/ChnUVv-I8653.html"
              target="_blank"
              icon={
                <img
                  src={require("../../../assets/image/download.png").default}
                />
              }
              type="primary"
            >
              即将开放
            </Button>
            <div className="tip">更新时间：2024年1月8日</div>
          </div>
        </div>
      </div> */}
      {/* <div className="container">
        <div className="qrcodes">
          <div className="tips">
            <div className="title">帮助社区 & 讨论</div>
            <div className="subtitle">
              FSM 2.0 版本安装使用，升级指引。硬件适配讨论，官方合作硬件购买指南
            </div>
          </div>
          <div className="qrcode-list">
            <div className="qrcode-list-item">
              <img
                src={require("../../../assets/image/qrcode-454.png").default}
              />
              <div className="txt">QQ扫码加入群聊</div>
            </div>
            <div className="qrcode-list-item">
              <img
                src={require("../../../assets/image/qrcode-dev.png").default}
              />
              <div className="txt">QQ扫码加入开发者</div>
            </div>
            <div className="qrcode-list-item">
              <img
                src={
                  require("../../../assets/image/1_1006984384_171_85_3_793848229_4f826da9db2e55bb6d0a7e847f4a08da.png")
                    .default
                }
              />
              <div className="txt">扫码看文档指引</div>
            </div>
          </div>
        </div>
      </div> */}

      <Modal
        getContainer={false}
        open={notifyVisible}
        width={650}
        footer={false}
        okText={"保存"}
        cancelText={"取消"}
        onOk={() => {}}
        onCancel={() => setNotifyVisible(false)}
        destroyOnClose
        className="modal-notify"
        centered
      >
        <div className="content">
          <h3 className="title mb-4">服务升级通知！</h3>
          <p className="tip">
            尊敬的用户您好，为了更好的提供服务，七朵云将于2024年9月7日09时起升级为统一登录认证系统，请您按照新账号的模式注册一个新账号，登录官网时请验证一下您原来注册的手机号即可完成绑定。如您的账号不存在，请扫描官网尾部的联系我微信二维码处理账号合并问题。
          </p>
          <p className="tip">登录流程提示：</p>
          <p className="tip">第一步：注册统一用户认证系统注册新账户</p>
          <p className="tip">第二步：登录官网系统（由统一认证系统登录授权）</p>
          <p className="tip">
            第三步：登录返回以后如账号不存在或未绑定可以通过手机号进行验证绑定
          </p>
          <p className="tip">
            完成上述步骤即可完成升级，如有问题可以联系官网客服处理。
          </p>
          <p className="tip">
            我们计划将在每两周内对官网和各项服务进行一次升级改造，以便完成对后续的FSM操作系统生态的支持。也欢迎大家在我们升级以后提出宝贵的建议和想法。
          </p>
          <p className="tip">感谢您对七朵云的支持！</p>
          {/* <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p>
            <p className="tip"></p> */}
        </div>
      </Modal>
    </div>
  );
};

export default Home;
