import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取省市县三级联动信息
 * @param {Object} parm 请求参数
 * @param {String} parm.code 省市代码
 * @returns
 */
export const GET_CITY = async (parm) =>
  await instance.post(`${base}/city`, parm);
