import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 用户登录
 * @param {Object} parm 请求参数
 * @param {String} parm.mobile 手机号
 * @param {String} parm.password 密码
 * @returns
 */
export const LOGIN = async (parm) =>
  await instance.post(`${base}/user/login`, parm);

/**
 * 获取短信码
 * @param {Object} parm 请求参数
 * @param {String} parm.mobile 手机号
 * @param {String} parm.type 使用类型 register forget login
 */
export const GET_SMS = async (parm) =>
  await instance.post(`${base}/user/getsms`, parm);

/**
 * 用户注册
 * @param {Object} parm 请求参数
 * @param {String} parm.mobile 手机号
 * @param {String} parm.smscode 短信码
 * @param {String} parm.password 密码
 */
export const REGISTER = async (parm) =>
  await instance.post(`${base}/user/register`, parm);

/**
 * 找回密码
 * @param {Object} parm 请求参数
 * @param {String} parm.mobile 手机号
 * @param {String} parm.smscode 短信码
 * @param {String} parm.password 密码
 */
export const FORGET = async (parm) =>
  await instance.post(`${base}/user/forget`, parm);

/**
 * 用户登录检测
 * @param {Object} parm 请求参数
 * @param {String} parm.mobile 手机号
 * @param {String} parm.password 密码
 * @returns
 */
export const CHECK = async (parm) =>
  await instance.post(`${base}/user/check`, parm);

/**
 * 用户信息
 * @returns
 */
export const GET_INFO = async () => await instance.get(`${base}/user/info`);

/**
 * 用户刷新token
 * @returns
 */
export const REFRESH_TOKEN = async (parm) =>
  await instance.post(`${base}/user/refresh`, parm);

/**
 * 用户退出
 * @returns
 */
export const LOGIN_OUT = async (parm) =>
  await instance.post(`${base}/user/logout`, parm);
