import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import { QRCodeCanvas } from "qrcode.react";

import { CREATE_JOINUS_ORDER } from "../../../api/goods";

const Active = (props) => {
  const {} = props;

  const [qrcodeUrl, setQrcodeUrl] = useState("");

  const [qrcodeVisible, setQrcodeVisible] = useState(false);

  const [subParams, setSubParams] = useState({});

  // 创建订单
  const order = async () => {
    const {
      data: { code, data, msg },
    } = await CREATE_JOINUS_ORDER();

    if (401 == code) {
      message.error("请先登录");
      return;
    }

    if (200 == code) {
      setQrcodeVisible(true);
      setQrcodeUrl(data?.code_url);
    }
  };

  // 关闭扫码框
  const closeQrcode = () => {
    setQrcodeVisible(false);
  };

  useEffect(() => {
    document.title = "FileStationOS - 专为家庭与企业设计的存储系统";
  }, []);
  return (
    <div className="active context">
      <div className="screen">
        <div className="cover">
          <img
            className="img"
            src="https://qiduo-1251034078.cos.ap-shanghai.myqcloud.com/bananer%2Fos_index.png"
          />
        </div>
        <div className="title">
          <div>FileStationOS</div>
          <div className="subtitle">专为存储场景设计的WebOS</div>
        </div>
        <div className="actions">
          <Button
            href="https://www.123pan.com/s/ChnUVv-Csq53.html"
            target="_blank"
            type="primary"
          >
            下载安装
          </Button>
          <Button href="https://txc.qq.com/products/409204" target="_blank">
            社区反馈
          </Button>
        </div>
      </div>
      <div className="context-item goods-intro">
        <div className="goods">
          <div className="name">FileStationOS</div>
          <div className="desc">FileStationOS 授权设备一台，国庆特惠价格</div>
          <div className="eventPrice">268元/台</div>
          <div className="price">原价899元</div>
          <div className="btn">
            <Button
              type="primary"
              href="/usercenter/device/list"
              target="_blank"
            >
              去购买
            </Button>
          </div>
        </div>
        <div className="goods">
          <div className="name">F1202 半高</div>
          <div className="desc">
            <div>12盘位桌面NAS机箱，ATX主板+ATX电源 </div>
            <div>(半高为FLEX电源，全高为ATX电源）</div>
          </div>
          <div className="eventPrice">1599元/台</div>
          <div className="price">原价1799元/台</div>
          <div className="btn">
            <Button type="primary" href="/product/buy/21" target="_blank">
              去购买
            </Button>
          </div>
        </div>
        <div className="goods">
          <div className="name">F1202 全高</div>
          <div className="desc">
            <div>12盘位桌面NAS机箱，ATX主板+ATX电源 </div>
            <div>(半高为FLEX电源，全高为ATX电源）</div>
          </div>
          <div className="eventPrice">1699元/台</div>
          <div className="price">原价1899元/台</div>
          <div className="btn">
            <Button type="primary" href="/product/buy/21" target="_blank">
              去购买
            </Button>
          </div>
        </div>
        <div className="goods">
          <div className="name">F2401 </div>
          <div className="desc">
            <div>24盘位NAS机箱，ATX主板+ATX电源</div>{" "}
            <div>(落地式24盘位+4盘位设计，万向轮）</div>
          </div>
          <div className="eventPrice">2599元/台</div>
          <div className="price">原价2999元/台</div>
          <div className="btn">
            <Button type="primary" href="/product/buy/6" target="_blank">
              去购买
            </Button>
          </div>
        </div>
      </div>
      <div className="context-item big-goods-intro">
        <div className="content">
          <div className="intro">
            <div className="title">D1201 全闪DIY机箱</div>
            <div className="desc">
              12盘位铝制面板，CNC工艺，骨架采用钢板骨架，全新设计DIY
              全闪U.3接口机箱
            </div>
          </div>
          <div className="price">
            <div className="eventPrice">2199元/台</div>
            <div className="price">原价2599元/台</div>
            <div className="btn">
              <Button type="primary" href="/product/buy/11" target="_blank">
                去购买
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="context-item big-goods-intro">
        <div className="content">
          <div className="intro">
            <div className="title">销售渠道加盟</div>
            <div className="desc">100台特价授权，伙伴加盟专用</div>
          </div>
          <div className="price">
            <div className="eventPrice">2000元</div>
            <div className="price">保证金可退款</div>
            <div className="btn">
              <Button
                type="primary"
                onClick={() => {
                  order();
                }}
              >
                去购买
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="context-item rule-intro">
        <div className="content">
          <div className="title">活动规则</div>
          <div className="desc">
            <div className="desc-item">
              1、购买机箱或者系统享受折扣前必须保证您已经购买了对应的一个产品，二者必须购买过机箱或者授权。
            </div>
            <div className="desc-item">
              {" "}
              2、此次活动进针对老用户复购，未购买过任何产品的不享受本活动价格。
            </div>
            <div className="desc-item">
              3、本次活动有效期自2023年9月29日-10月9日，过期将不支持参与。
            </div>
            <div className="desc-item">
              4、代理渠道申请成功以后，自加入之日起365天以后可以申请降级或退出伙伴资格，届时并退还保证金。
            </div>
            <div className="desc-item">
              5、本次活动参与的销售的产品，不享受买贵必赔服务。
            </div>
            <div className="desc-item">6、活动参与以后不支持无理由退款。</div>
            <div className="desc-item">
              7、实物商品发货时间为10月25日至11月30日。（请留意官方通知）
            </div>
            <div className="desc-item">
              8、本活动如有任何疑问可以向销售咨询。
            </div>
          </div>
        </div>
      </div>

      {(qrcodeVisible && (
        <Modal
          open={qrcodeVisible}
          width={600}
          closable={false}
          footer={[
            <div
              className="price-info text-left"
              style={{
                position: "absolute",
                left: "15px",
                marginTop: "0px",
              }}
            >
              <div>
                <span className="label">实付金额：</span>
                <span
                  className="price"
                  style={{
                    fontSize: 20,
                    fontWeight: "bolder",
                    marginRight: "15px",
                  }}
                >
                  ￥2000.00
                </span>
              </div>
            </div>,
            <Button
              onClick={() => {
                closeQrcode();
              }}
            >
              未完成支付
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                closeQrcode();
              }}
            >
              已完成支付
            </Button>,
          ]}
        >
          <div className="tips mb-5">
            请确认您购买授权后将不支持退款，支付后相关款项会从微信中扣除，如果您不确定是否有强烈的需求，请您先体验30天后转为正式授权即可。
          </div>
          <div className="text-center">
            <QRCodeCanvas width={300} value={qrcodeUrl} />
          </div>
        </Modal>
      )) ||
        ""}
    </div>
  );
};

export default Active;
