import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Spin,
  Modal,
  Checkbox,
  message,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  MobileOutlined,
  MailOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./login.less";
import { FORGET, GET_SMS } from "../../api/login";
import cookie from "react-cookies";
import uitls from "../../assets/js/public";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
      sendDisabled: false,
      time: 120,
      formVals: {},
    };
  }

  componentDidMount = () => {
    document.title = "找回密码";
  };

  count = () => {
    let { time } = this.state;
    let siv = setInterval(() => {
      this.setState({ time: time-- }, () => {
        if (time <= -1) {
          clearInterval(siv); //倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          this.setState({ sendDisabled: false, time: 120 });
        }
      });
    }, 1000);
  };

  forget = async (values) => {
    this.setState({ loading: true });
    if (values.password != values.userPwdVeryf) {
      message.error("密码与确认密码不一致");
      return false;
    }
    // values = Object.assign(values);
    if (!localStorage.getItem("codeid")) {
      message.error("请先获取短信验证码");
      return false;
    }
    delete values.read;
    delete values.userPwdVeryf;
    // values.code_id = localStorage.getItem("codeid");
    let res = await FORGET(values);
    console.log(res);
    if (200 == res.status && 200 == res.data.code) {
      message.success("重置密码成功,请进行登录操作");
      localStorage.removeItem("codeid");
      this.props.history.push("/login");
    } else {
      console.log(res?.data?.msg || res.statusText);
      const text = res?.data?.msg || res.statusText;
      message.error(text);
    }
    this.setState({ loading: false });
  };

  render() {
    const { formVals } = this.state;
    return (
      <div className="login-cont">
        <div className="copyright">
          <p>
            Copyright © 2023 宿州七朵信息技术有限公司
            <a href="https://beian.miit.gov.cn/" target="_blank">
              沪ICP备2020033741号-1
            </a>
          </p>
        </div>
        <div className="login-register">
          <div className="title">
            {/* <h1>重置密码</h1> */}
            <p>
              {/* 没有账号？ */}
              <Link to="/">
                <ArrowLeftOutlined className="mr-1" />
                返回首页
              </Link>
            </p>
          </div>
          <Form
            className="login-form"
            onFinish={this.forget}
            name="normal_login"
            initialValues={{ remember: true }}
            onValuesChange={(vals) => {
              let formVals = { ...this.state.formVals, ...vals };
              this.setState({ formVals: formVals });
            }}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                size="large"
                allowClear
                prefix={
                  <MobileOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                placeholder="请输入手机号"
              />
            </Form.Item>

            <Form.Item>
              <Row gutter={8}>
                <Col span={14}>
                  <Form.Item
                    name="smscode"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "请输入短信码",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      allowClear
                      prefix={
                        <MailOutlined
                          style={{
                            color: "rgba(0,0,0,.25)",
                            fontSize: "18px",
                          }}
                        />
                      }
                      placeholder="请输入短信码"
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Button
                    size="large"
                    block
                    disabled={this.state.sendDisabled}
                    onClick={async () => {
                      if (!uitls.phone(formVals.mobile)) {
                        message.error("请输入正确的手机号码");
                        return;
                      }

                      if (this.state.time > 0) {
                        this.setState({ sendDisabled: true });
                        let res = await GET_SMS({
                          mobile: formVals.mobile,
                          type: "forget",
                        });
                        console.log(res);
                        if (200 == res.status && 200 == res.data.code) {
                          message.success("短信发送成功，请注意查收");
                          this.count();
                          localStorage.setItem(
                            "codeid",
                            res.data.data.chuanglan.result.msgId
                          );
                        } else {
                          this.setState({ sendDisabled: false, time: 120 });
                        }
                      }
                    }}
                  >
                    {this.state.sendDisabled
                      ? this.state.time + "秒"
                      : "获取短信码"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item
              name="userPwdVeryf"
              rules={[{ required: true, message: "请再次输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请再次输入密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                重置密码
              </Button>
            </Form.Item>

            <div className="agreement my-3">
              <Form.Item
                name="read"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("请勾选协议")),
                  },
                ]}
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <Checkbox>
                  我已阅读并接受<a>《服务协议》</a>和<a>《隐私协议》</a>
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item>
              <Link to="/login">
                <Button
                  type="primary"
                  className="login-form-button"
                  size="large"
                  style={{ fontSize: "14px", borderRadius: "5px" }}
                  block
                >
                  已有帐号？返回登陆
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
