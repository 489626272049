import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

// 用户登录
import Login from "./login/login";
// 用户注册
import Register from "./login/register";
// 用户找回
import Forget from "./login/forget";
// 用户检测
import Check from "./login/check";
// 前台
import Front from "./front";
// 用户中心
import UserCenter from "./usercenter";

class Pages extends React.Component {
  state = {
    login_state: false,
  };
  componentDidMount() {}
  componentWillUnmount() {
    document.title = "七朵云";
    this.setState = () => false;
  }
  render() {
    return (
      <div className="full">
        <Router>
          <Switch>
            {/* 用户登录注册 */}
            {/* <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/forget" exact component={Forget} /> */}
            <Route path="/check" exact component={Check} />
            
            {/* 首页 */}
            <Route path="/" component={Front} />

            <Redirect to={`/`} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default Pages;
