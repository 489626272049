import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory, Link } from "react-router-dom";
import { Spin, Layout, Card, Menu, Button, Dropdown } from "antd";
import {
  DownOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { GET_INFO, REFRESH_TOKEN, LOGIN_OUT } from "../../api/login";

import cookie from "react-cookies";
import "./index.less";

//公共函数
import utils from "../../assets/js/public";
import basicRouters from "../../assets/json/basicRouters.json";

//页面引入
import Overview from "./overview"; //概览
import Device from "./device"; //设备
import Order from "./order"; //订单
import AgentDevice from "./agent/device"; //代理商授权购买

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const UserCenter = (props) => {
  const history = useHistory();

  const [userInfo, setUserInfo] = useState();
  const [curMenu, setCurMenu] = useState("");

  const isLogin = async () => {
    if (!cookie.load("accessToken")) {
      cookie.remove("accessToken", { path: "/" });
      localStorage.removeItem("userInfo");
      history.push("/");
      return true;
    }

    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    return false;
  };

  const getUserInfo = async () => {
    const {
      data: { data, code, msg },
    } = await GET_INFO();
    if (code == 200) {
      // console.log(res.data)
      setUserInfo(data);
      localStorage.setItem("userInfo", JSON.stringify(data));
    }
  };

  const menus = [
    {
      label: <Link to="/usercenter/overview">总览</Link>,
      key: "/usercenter/overview",
      icon: (
        <img src={require("../../assets/image/usercent/menu-1.svg").default} />
      ),
    },
    {
      label: "设备管理",
      key: "device",
      type: "group",
      children: [
        {
          label: <Link to="/usercenter/device/list">我的设备</Link>,
          key: "/usercenter/device/list",
          icon: (
            <img
              src={require("../../assets/image/usercent/menu-2.svg").default}
            />
          ),
        },
      ],
    },
    {
      label: "订单管理",
      key: "order",
      type: "group",
      children: [
        {
          label: <Link to="/usercenter/order/list">我的订单</Link>,
          key: "/usercenter/order/list",
          icon: (
            <img
              src={require("../../assets/image/usercent/menu-3.svg").default}
            />
          ),
        },
      ],
    },
    {
      label: "售后服务",
      key: "service",
      type: "group",
      children: [
        {
          label: <Link to="/usercenter/service/list">工单管理</Link>,
          key: "/usercenter/service/list",
          icon: (
            <img
              src={require("../../assets/image/usercent/menu-4.svg").default}
            />
          ),
        },
      ],
    },
    // {
    //   label: "代理商管理",
    //   key: "agent",
    //   type: "group",
    //   isAgent: true,
    //   children: [
    //     {
    //       label: <Link to="/usercenter/agent/device">授权购买</Link>,
    //       key: "/usercenter/agent/device",
    //       icon: <UserOutlined />,
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    isLogin();
    getUserInfo();
  }, []);

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  useEffect(() => {
    // console.log(history.location.pathname);
    setCurMenu(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <div className="usercenter">
      <Layout hasSider>
        <Sider>
          <Menu
            theme="light"
            mode="vertical"
            // openKeys={["6"]}
            selectedKeys={[curMenu]}
            // defaultSelectedKeys={[curMenu]}
            items={[
              ...menus.filter((item) => {
                // 过滤代理商菜单
                if (!!item?.isAgent && !!userInfo?.agent_id) {
                  return item;
                } else {
                  if (!item?.isAgent) {
                    return item;
                  }
                }
              }),
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Content>
            <Switch>
              {/* 概览 */}
              <Route path="/usercenter/overview" exact component={Overview} />
              <Route path="/usercenter/order/list" exact component={Order} />
              <Route path="/usercenter/device/list" exact component={Device} />
              <Route
                path="/usercenter/agent/device"
                exact
                component={AgentDevice}
              />

              <Redirect to={`/usercenter/overview`} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default UserCenter;
