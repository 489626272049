import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Menu,
  Button,
  Modal,
  message,
  Carousel,
  Collapse,
  Anchor,
  Input,
  InputNumber,
  Select,
  Spin,
} from "antd";
import cookie from "react-cookies";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusOutlined,
  RightOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { debounce, throttle } from "lodash";
import { QRCodeCanvas } from "qrcode.react";
import "./index.less";

import { GET_PRICE, BUY_DEVICE_AUTH } from "../../api/deviceAuth";
import { GET_USER_USE_COUPON_LIST } from "../../api/coupon";

/**
 *
 * @param {Boolean} visible 控制modal 是否展示
 * @param {Function} setVisible 改变visible状态的函数
 * @param {Number} version 默认选择的授权版本枚举 null 全部显示 1家庭版 2企业版
 * @param {Number} type 默认选择的授权类型枚举 1订阅 2一次性
 * @returns
 */
const PayDeviceAuth = ({
  visible,
  setVisible,
  version: authVersion,
  type: authType,
}) => {
  const [basePrice, setBasePrice] = useState({});
  const [price, setPrice] = useState({});
  const [version, setVersion] = useState(authVersion || null);
  const [type, setType] = useState(authType || 1);

  const [years, setYears] = useState(1);
  const [num, setNum] = useState(5);

  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [couponList, setCouponList] = useState([]);
  const [chooseCoupon, setChooseCoupon] = useState(null);

  // 获取所有价格
  const getPrices = async () => {
    const {
      data: { data, code, msg },
    } = await GET_PRICE();
    if (200 === code) setBasePrice(data);
  };

  /**
   * 更改授权选择 并获取价格
   * @param {Number} v 版本
   * @param {Number} t 类型
   */
  const changeChoose = async (v, t) => {
    setLoading(true);
    setVersion(v);
    setType(t);
  };

  useEffect(() => {
    if (!!visible) {
      getPrices();
    }
  }, [visible]);

  const buy = useCallback(
    throttle(async (version, type, years, num, chooseCoupon) => {
      setLoading(true);

      const {
        data: { data, code, msg },
      } = await BUY_DEVICE_AUTH({
        payType: 1,
        version: version || 1,
        type: type || 1,
        duration: years || 1,
        memberNum: num || 5,
        useCouponId: chooseCoupon?.id,
      });

      if (chooseCoupon?.coupon_info?.threshold_amount > data.originalMoney) {
        setChooseCoupon(undefined);
      }

      useCouponList(data.originalMoney);

      if (200 === code) {
        setQrcodeUrl(data.code_url);
        setLoading(false);
      } else {
        message.error(msg);
      }

      console.log(version, type, years, num);
    }, 100),
    []
  );

  // 获取用户可用优惠券列表
  const useCouponList = async (price) => {
    const {
      data: { data, code, msg },
    } = await GET_USER_USE_COUPON_LIST({
      price: price,
      is_auth: 1,
    });

    if (200 === code) {
      setCouponList(
        data.resource.map((item) => ({
          ...item,
          coupon_info: JSON.parse(item.coupon_info),
        }))
      );
    }
  };

  useEffect(() => {
    if (!!visible) {
      buy(version, type, years, num, chooseCoupon);
    }
  }, [visible, version, type, years, num, chooseCoupon]);

  return (
    (visible && (
      <Modal
        title="购买授权"
        open={visible}
        width={480}
        footer={null}
        keyboard={false}
        maskClosable={false}
        centered
        onCancel={() => {
          setVisible(false);
        }}
      >
        <div className="pay-cont">
          <div className="pay-list">
            {version !== 2 && (
              <div className="item home">
                <div
                  className={`pay-list-item ${
                    version == 1 && type == 1 && "cur"
                  }`}
                  onClick={() => {
                    changeChoose(1, 1);
                  }}
                >
                  <div className="head">家庭版订阅</div>
                  <div className="body">
                    <div className="price">
                      {(basePrice?.home?.subPrice &&
                        (basePrice?.home?.subPrice * years).toFixed(2)) ||
                        "-"}
                    </div>
                    <div className="intro">每年/台</div>
                    <div className="action">
                      <InputNumber
                        value={years}
                        addonBefore={
                          <Button
                            disabled={(years <= 1 && true) || false}
                            onClick={() => {
                              setLoading(true);
                              setYears(years - 1);
                            }}
                          >
                            <MinusOutlined />
                          </Button>
                        }
                        addonAfter={
                          <Button
                            onClick={() => {
                              setLoading(true);
                              setYears(years + 1);
                            }}
                          >
                            <PlusOutlined />
                          </Button>
                        }
                        step={1}
                        min={1}
                        readOnly
                        controls={false}
                        onChange={(val) => {
                          setLoading(true);
                          setYears(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`pay-list-item ${
                    version == 1 && type == 2 && "cur"
                  }`}
                  onClick={() => {
                    setLoading(true);
                    changeChoose(1, 2);
                  }}
                >
                  <div className="head">家庭版终身</div>
                  <div className="body">
                    <div className="price">
                      {(basePrice?.home?.price && basePrice?.home?.price) ||
                        "-"}
                    </div>
                    <div className="intro">台/终身</div>
                  </div>
                </div>
              </div>
            )}
            {version !== 1 && (
              <div className="item qy">
                <div
                  className={`pay-list-item ${
                    version == 2 && type == 1 && "cur"
                  }`}
                  onClick={() => {
                    setLoading(true);
                    changeChoose(2, 1);
                  }}
                >
                  <div className="head">企业版订阅</div>
                  <div className="body">
                    <div className="price">
                      {(basePrice?.qy?.subPrice &&
                        (basePrice?.qy?.subPrice * num).toFixed(2)) ||
                        "-"}
                    </div>
                    <div className="intro">年/成员/台</div>
                    <div className="action">
                      <InputNumber
                        value={num}
                        addonBefore={
                          <Button
                            disabled={(num <= 5 && true) || false}
                            onClick={() => {
                              setLoading(true);
                              setNum(num - 5);
                            }}
                          >
                            <MinusOutlined />
                          </Button>
                        }
                        addonAfter={
                          <Button
                            onClick={() => {
                              setLoading(true);
                              setNum(num + 5);
                            }}
                          >
                            <PlusOutlined />
                          </Button>
                        }
                        step={5}
                        min={5}
                        readOnly
                        controls={false}
                        onChange={(val) => {
                          setLoading(true);
                          setNum(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="coupon mb-3">
            <div className="label"></div>
            <div className="value">
              <Select
                allowClear
                value={
                  (chooseCoupon?.coupon_info &&
                    `${chooseCoupon?.coupon_info?.title}/满${Number(
                      chooseCoupon?.coupon_info?.threshold_amount
                    )}减${Number(chooseCoupon?.coupon_info?.amount)}`) ||
                  undefined
                }
                onChange={(val) => {
                  const info = couponList.find((item) => item.id == val);
                  setChooseCoupon({
                    ...info,
                  });
                }}
                options={[
                  ...couponList.map((item) => ({
                    label: `${item.coupon_info.title}/满${Number(
                      item.coupon_info.threshold_amount
                    )}减${Number(item.coupon_info.amount)}`,
                    value: item.id,
                  })),
                ]}
                placeholder="不使用优惠券"
                style={{ width: 350 }}
              />
            </div>
          </div>
          <div className="pay-info">
            <div className="tip mb-3">请使用微信扫码支付</div>
            <div className="qrcode mb-4">
              <div className="cont">
                {(loading && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 42 }} spin />
                    }
                  />
                )) ||
                  ""}
                <QRCodeCanvas width={300} value={qrcodeUrl} />
              </div>
            </div>
            <div className="tip">
              支付前请确认已阅读《七朵云产品订购服务协议》
            </div>
          </div>
          <div className="actions text-center mt-3">
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
              }}
            >
              我已扫码并支付
            </Button>
          </div>
        </div>
      </Modal>
    )) ||
    ""
  );
};

export default PayDeviceAuth;
