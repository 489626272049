import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取商品分类列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_GOODS_CATEGORY_LIST = async (parm) =>
  await instance.get(`${base}/user/goods/category/list`, parm);

/**
 * 获取商品分类详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const GET_GOODS_CATEGORY_DETAIL = async () =>
  await instance.get(`${base}/user/goods/category/detail`);
