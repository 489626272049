import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取订单列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_ORDER_LIST = async (parm) =>
  await instance.post(`${base}/user/usercenter/order/list`, parm);

/**
 * 获取订单详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const GET_ORDER_DETAIL = async (parm) =>
  await instance.get(
    `${base}/user/usercenter/order/detail?${qs.stringify(parm)}`,
    parm
  );

/**
 * 支付订单(返回二维码用于扫码支付)
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const PAY_ORDER = async (parm) =>
  await instance.post(`${base}/user/usercenter/order/pay`, parm);
