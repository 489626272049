import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取版本产品列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_PRODUCT_LIST = async (parm) =>
  await instance.post(`${base}/version/product/list`, parm);

/**
 * 获取版本型号列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_MODEL_LIST = async (parm) =>
  await instance.post(`${base}/version/model/list`, parm);

/**
 * 获取版本引导列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_GUIDE_LIST = async (parm) =>
  await instance.post(`${base}/version/guide/list`, parm);

/**
 * 获取版本镜像列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_VERSION_IMAGE_LIST = async (parm) =>
  await instance.post(`${base}/version/image/list`, parm);
