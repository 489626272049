import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Menu,
  Button,
  Modal,
  message,
  Carousel,
  Select,
  Tabs,
  Step,
} from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  CheckOutlined,
  EnvironmentFilled,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

import {
  GET_VERSION_PRODUCT_LIST,
  GET_VERSION_MODEL_LIST,
  GET_VERSION_GUIDE_LIST,
  GET_VERSION_IMAGE_LIST,
} from "../../../api/version";
import { faltArray, tree2line } from "../../../common/utils";

const Download = (props) => {
  const {} = props;

  const [product, setProduct] = useState([]);
  const [model, setModel] = useState([]);
  const [guide, setGuide] = useState([]);
  const [image, setImage] = useState([]);
  const [chooseProduct, setChooseProduct] = useState(undefined);
  const [chooseModel, setChooseModel] = useState(undefined);
  const [chooseGuide, setChooseGuide] = useState(undefined);
  const [chooseImage, setChooseImage] = useState(undefined);
  const [chooseTargetImage, setChooseTargetImage] = useState(undefined);
  const [uplist, setUplist] = useState([]);

  const [tabs, setTabs] = useState([
    { key: 1, label: "引导程序" },
    { key: 2, label: "操作系统镜像" },
    { key: 3, label: "应用" },
    { key: 4, label: "客户端" },
    { key: 5, label: "驱动" },
    { key: 6, label: "其他文件" },
  ]);
  const [cur, setCur] = useState(1);

  const getProduct = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_PRODUCT_LIST({
      pageNo: 1,
      pageSize: 999999,
    });

    if (200 === code) {
      setProduct(data.resource);
    }
  };

  const getModel = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_MODEL_LIST({
      pageNo: 1,
      pageSize: 999999,
      where: {
        product_id: chooseProduct?.id,
      },
    });

    if (200 === code) {
      setModel(data.resource);
    }
  };

  const getGuide = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_GUIDE_LIST({
      pageNo: 1,
      pageSize: 999999,
      where: {
        model_id: chooseModel?.id,
      },
    });

    if (200 === code) {
      setGuide(data.resource);
    }
  };

  const getImage = async () => {
    const {
      data: { code, data, msg },
    } = await GET_VERSION_IMAGE_LIST({
      pageNo: 1,
      pageSize: 999999,
      where: {
        model_id: chooseModel?.id,
      },
    });

    if (200 === code) {
      setImage(data.resource);
    }
  };

  useEffect(() => {
    if (chooseModel?.id) {
      getGuide();
      getImage();
    }
  }, [chooseModel]);

  useEffect(() => {
    if (chooseProduct?.id) getModel();
  }, [chooseProduct]);

  useEffect(() => {
    document.title =
      "FileStation Manager 下载中心 - 七朵云 - 存储解决方案服务商";

    getProduct();
  }, []);

  useEffect(() => {
    if (chooseImage && chooseTargetImage) {
      const old = image.find((item) => item.id == chooseImage);
      const list = [
        image.find((item) => item.id == chooseTargetImage),
        ...faltArray(
          image.find((item) => item.id == chooseTargetImage)?.historyVersions
        ),
      ].filter((item) => item.id >= old.id);

      setUplist(list.reverse());
      console.log(list);
    }
  }, [chooseImage, chooseTargetImage]);

  const onMenuClick = (e) => {
    console.log("click ", e);
    setMenuKey(e.key);
  };
  return (
    <div className="version context">
      {/* <div className="carousel">
        <Carousel autoplay afterChange={onChange}>
          <div className="cover-img">
            <img
              src={require("../../../assets/image/banner/banner1.jpg").default}
              style={{ width: "100%" }}
            />
          </div>
        </Carousel>
      </div> */}
      {/* <div className="banner"></div> */}
      <div className="top">
        <div className="container">
          <div className="title">下载中心</div>
          <div className="subtitle">
            您可以找到关于操作系统、套件、桌面实用程序等 FSM操作系统
            产品的文档和文件，以便享有多种新功能。
          </div>
          <div className="tip">请选择您使用的产品类别和相应型号。</div>
          <div className="actions">
            <div className="actions-item">
              <Select
                placeholder="请选择产品"
                showSearch
                size="large"
                options={product.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(val) =>
                  setChooseProduct(product.find((f) => f.id === val))
                }
                style={{ minWidth: 220 }}
              />
            </div>
            {chooseProduct?.id && (
              <div className="actions-item">
                <Select
                  placeholder="请选择型号"
                  showSearch
                  size="large"
                  options={model.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(val) =>
                    setChooseModel(model.find((f) => f.id === val))
                  }
                  style={{ minWidth: 220 }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {chooseModel?.name && (
        <div className="result">
          <div className="container py-5">
            <div className="title">搜索结果：{chooseModel?.name}</div>
            <div className="nav">
              <Tabs items={tabs} accessKey={cur} onChange={(k) => setCur(k)} />
            </div>
            {cur == 1 && (
              <div className="tabs-item guide">
                <div className="list">
                  {guide
                    .filter((item, ind) => ind == 0)
                    .map((item) => (
                      <div className="list-item" key={item.id}>
                        <div className="name">FSM {item.name}</div>
                        <div className="intro">
                          <div className="info">{item.intro}</div>
                          <div className="actions">
                            <Button
                              className="mr-3"
                              type="primary"
                              href={item.url}
                              target="_blank"
                            >
                              下载
                            </Button>
                            {/* <Button>MD5</Button> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {(cur == 2 && (
              <div className="tabs-item image">
                <div className="list">
                  {image
                    .filter((item, ind) => ind == 0)
                    .map((item) => (
                      <div className="list-item" key={item.id}>
                        <div className="name">FSM {item.name}</div>
                        <div className="intro">
                          <div className="info">{item.intro}</div>
                          <div className="actions">
                            <Button
                              className="mr-3"
                              type="primary"
                              href={item.url}
                              target="_blank"
                            >
                              下载
                            </Button>
                            {/* <Button>MD5</Button> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="appoint mt-5">
                  <div className="title">从现有 FSM 版本更新到预期版本</div>
                  <div className="subtitle">
                    请选择当前 FSM 版本和欲更新版本。下载完成后，进入 FSM
                    控制面板以运行手动更新。
                  </div>
                  <div className="choose-list">
                    <div className="choose-list-item">
                      <Select
                        value={chooseImage}
                        placeholder="请选择 FSM 版本"
                        options={image
                          .filter((item, ind) => ind != 0)
                          .map((item) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                        showSearch
                        size="large"
                        style={{ minWidth: 300 }}
                        onChange={(val) => {
                          setChooseImage(val);
                          setChooseTargetImage(null);
                          setUplist([]);
                        }}
                      />
                    </div>
                    <div className="choose-list-item icon">
                      <RightOutlined />
                    </div>
                    <div className="choose-list-item">
                      <Select
                        value={chooseTargetImage}
                        placeholder="请选择预期 FSM 版本"
                        disabled={!chooseImage}
                        options={image
                          .filter((item, ind) => item.id > chooseImage)
                          .map((item) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                        showSearch
                        size="large"
                        style={{ minWidth: 300 }}
                        onChange={(val) => {
                          setChooseTargetImage(val);
                        }}
                      />
                    </div>
                  </div>
                  {(uplist?.length && (
                    <div className="update-list">
                      {uplist.map((item, index) => (
                        <div className="update-list-item" key={item.id}>
                          <div className={`icon ${index == 0 ? "now" : ""}`}>
                            {(index == 0 && <EnvironmentFilled />) || index}
                          </div>
                          <div className={`dot ${index == 0 ? "now" : ""}`}>
                            <div className={`item`}>
                              {index == 0 && <CheckOutlined />}
                            </div>
                          </div>
                          <div className="info">
                            <div className="name">{item.name}</div>
                            {index != 0 && (
                              <div className="download">
                                <Button
                                  type="text"
                                  href={item.url}
                                  target="_blank"
                                >
                                  下载
                                </Button>
                              </div>
                            )}
                            {/* {index != 0 && (
                              <div className="md5">
                                <Button>MD5</Button>
                              </div>
                            )} */}
                          </div>
                        </div>
                      )) || ''}
                    </div>
                  )) ||
                    ""}
                </div>
              </div>
            )) ||
              ""}
            {(cur == 3 && (
              <div className="tabs-item app">
                <div className="list">
                  {chooseModel?.docslist?.length &&
                    chooseModel?.docslist
                      .filter((item) => item.type === 1)
                      .map((item) => (
                        <div className="list-item" key={item.id}>
                          <div className="name">{item.name}</div>
                          <div className="intro">
                            <div className="info">{item.intro}</div>
                            <div className="actions">
                              <Button
                                className="mr-3"
                                type="primary"
                                href={item.url}
                                target="_blank"
                              >
                                下载
                              </Button>
                              {/* <Button>MD5</Button> */}
                            </div>
                          </div>
                        </div>
                      )) || ''}
                </div>
              </div>
            )) ||
              ""}
            {(cur == 4 && (
              <div className="tabs-item client">
                <div className="list">
                  {chooseModel?.docslist?.length &&
                    chooseModel?.docslist
                      .filter((item) => item.type === 2)
                      .map((item) => (
                        <div className="list-item" key={item.id}>
                          <div className="name">{item.name}</div>
                          <div className="intro">
                            <div className="info">{item.intro}</div>
                            <div className="actions">
                              <Button
                                className="mr-3"
                                type="primary"
                                href={item.url}
                                target="_blank"
                              >
                                下载
                              </Button>
                              {/* <Button>MD5</Button> */}
                            </div>
                          </div>
                        </div>
                      )) || ''}
                </div>
              </div>
            )) ||
              ""}
            {(cur == 5 && (
              <div className="tabs-item drive">
                <div className="list">
                  {chooseModel?.docslist?.length &&
                    chooseModel?.docslist
                      .filter((item) => item.type === 3)
                      .map((item) => (
                        <div className="list-item" key={item.id}>
                          <div className="name">{item.name}</div>
                          <div className="intro">
                            <div className="info">{item.intro}</div>
                            <div className="actions">
                              <Button
                                className="mr-3"
                                type="primary"
                                href={item.url}
                                target="_blank"
                              >
                                下载
                              </Button>
                              {/* <Button>MD5</Button> */}
                            </div>
                          </div>
                        </div>
                      )) || ''}
                </div> 
              </div>
            )) ||
              ""}
            {(cur == 6 && (
              <div className="tabs-item other">
                <div className="list">
                  {chooseModel?.docslist?.length &&
                    chooseModel?.docslist
                      .filter((item) => item.type === 4)
                      .map((item) => (
                        <div className="list-item" key={item.id}>
                          <div className="name">{item.name}</div>
                          <div className="intro">
                            <div className="info">{item.intro}</div>
                            <div className="actions">
                              <Button
                                className="mr-3"
                                type="primary"
                                href={item.url}
                                target="_blank"
                              >
                                下载
                              </Button>
                              {/* <Button>MD5</Button> */}
                            </div>
                          </div>
                        </div>
                      )) || ''}
                </div>
              </div>
            )) ||
              ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default Download;
