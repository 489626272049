import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Row, Col, Menu, Button, Modal, message, Carousel } from "antd";
import cookie from "react-cookies";
import "./index.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";

const Product = (props) => {
  const {} = props;

  useEffect(() => {
    document.title = "产品列表";
  }, []);
  return (
    <div className="product context">
      <div className="cover"></div>
    </div>
  );
};

export default Product;
