import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取销售渠道列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_CHANNEL_LIST = async (parm) =>
  await instance.post(`${base}/saleschannels/list`, parm);
