import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取商品列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_GOODS_LIST = async (parm) =>
  await instance.post(`${base}/user/goods/list`, parm);

/**
 * 获取商品详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 查询项ID
 * @returns
 */
export const GET_GOODS_DETAIL = async (parm) =>
  await instance.get(`${base}/user/goods/detail?${qs.stringify(parm)}`, parm);

/**
 * 创建商品订单(返回二维码用于扫码支付)
 * @param {Object} parm 请求参数
 * @param {String} parm.goods 商品信息快照
 * @param {String} parm.goods_sku 商品规格信息快照
 * @param {String} parm.provinces_code 收货省份代码
 * @param {String} parm.provinces_name 收货省份名称
 * @param {String} parm.cities_code 收货城市代码
 * @param {String} parm.cities_name 收货城市名称
 * @param {String} parm.areas_code 收货区县代码
 * @param {String} parm.areas_name 收货区县名称
 * @param {String} parm.address 详细收货地址
 * @param {String} parm.contact_name 收货人名称
 * @param {String} parm.contact_mobile 收货人联系电话
 * @returns
 */
export const CREATE_GOODS_ORDER = async (parm) =>
  await instance.post(`${base}/user/goods/order`, parm);


/**
 * 创建加盟订单(返回二维码用于扫码支付)
 * @param {Object} parm 请求参数
 * @returns
 */
export const CREATE_JOINUS_ORDER = async (parm) =>
  await instance.post(`${base}/user/goods/joinusOrder`, parm);