import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";

/**
 * 获取代理商购买设备列表
 * @param {Object} parm 请求参数
 * @param {Number} parm.pageSize 每页数量
 * @param {Number} parm.pageNo 当前页码
 * @returns
 */
export const GET_AGENT_AGENT_DEVICE_LIST = async (parm) =>
  await instance.post(`${base}/user/usercenter/agent/device/list`, parm);

/**
 * 获取代理商购买设备详情
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 等级项ID
 * @returns
 */
export const GET_AGENT_DEVICE_DETAIL = async () =>
  await instance.get(`${base}/user/usercenter/agent/device/detail`);

/**
 * 获取代理商购买设备价格
 * @returns
 */
export const GET_AGENT_PRICE = async (parm) =>
  await instance.get(`${base}/user/usercenter/agent/device/price`, parm);

/**
 * 代理商购买设备
 * @param {Object} parm 请求参数
 * @param {Number} parm.id 设备项ID
 * @returns
 */
export const BUY_AGENT_DEVICE = async (parm) =>
  await instance.post(`${base}/user/usercenter/agent/device/buy`, parm);
