import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Modal,
  message,
  Badge,
  Form,
  Input,
  Pagination,
  Empty,
  Typography,
  Popconfirm,
  Table,
} from "antd";
import cookie from "react-cookies";
import "./device.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  HddFilled,
} from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import { QRCodeCanvas } from "qrcode.react";
import {
  GET_AGENT_AGENT_DEVICE_LIST,
  GET_AGENT_DEVICE_DETAIL,
  GET_AGENT_PRICE,
  BUY_AGENT_DEVICE,
} from "../../../api/agentDevice";

const CreateForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (info) {
        info.icon && setIconFile([info.icon]);

        form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={650}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-create"
      centered
    >
      <div className="title">
        <h1>{info.id ? "编辑" : "购买"}授权</h1>
      </div>
      <div className="content">
        <Row gutter={30}>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                ...info,
              }}
              name="form_in_modal"
              onValuesChange={(vals, allVals) => {
                setFormVals(allVals);
              }}
            >
              <div>
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="mobile" label="用户手机号">
                  <Input />
                </Form.Item>
              </div>
            </Form>
            <div className="mt-3">
              <Button
                type="primary"
                size="large"
                className="py-3 px-5"
                style={{ height: "auto", lineHeight: "1" }}
                onClick={() => {
                  let params = { ...form.getFieldsValue() };
                  onCreate(params);
                }}
              >
                {info.id ? "编辑" : "购买"}授权
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const Device = (props) => {
  const {} = props;
  const [userInfo, setUserInfo] = useState();
  const [visibleInfo, setVisibleInfo] = useState({});
  const [visibleCreate, setVisibleCreate] = useState(false);

  const [price, setPrice] = useState({});
  const [qrcodeUrl, setQrcodeUrl] = useState("");
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const qrcodeTimeRef = useRef();

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    } else {
      setUserInfo({});
    }
  }, [localStorage.getItem("userInfo")]);

  // 购买设备
  const onBuy = async (values) => {
    // console.log(values)
    const {
      data: { code, data, msg },
    } = await BUY_AGENT_DEVICE(values);
    if (code == 200) {
      // message.success("购买设备成功");
      // getList(pagination);
      if (data?.result_code == "SUCCESS") {
        getPrice();
        setQrcodeUrl(data?.code_url);
      }
    } else {
      message.error(msg);
    }
  };

  // 获取设备售价
  const getPrice = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_AGENT_PRICE();
    if (code == 200) {
      setPrice(data);
      setQrcodeVisible(true);
    }
  };

  const getList = async (paginations) => {
    const {
      data: { code, data, msg },
    } = await GET_AGENT_AGENT_DEVICE_LIST({ ...paginations });
    if (code == 200) {
      setList(data.resource);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };

  // 分页操作
  const onPageChange = (page) => {
    setPagination({
      ...pagination,
      pageNo: page,
    });
    getList({
      ...pagination,
      pageNo: page,
    });
  };

  // 关闭扫码框
  const closeQrcode = () => {
    setQrcodeVisible(false);
    getList(pagination);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  useEffect(() => {
    document.title = "用户中心 - 授权购买";
  }, []);

  return (
    <div className="agent-device context">
      <div className="actions">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          购买授权
        </Button>
      </div>
      <div className="device-list">
        <div className="device-list-head">
          <div className="item id">ID</div>
          <div className="item user">用户手机号</div>
          <div className="item price">购买金额</div>
          <div className="item time">购买时间</div>
          {/* <div className=""></div> */}
        </div>
        {list.map((item, index) => (
          <div className="device-list-item" key={index}>
            <div className="item id">{item.id}</div>
            <div className="item user">{item.mobile}</div>
            <div className="item price">￥{item.price}</div>
            <div className="item time">{item.created_at}</div>
          </div>
        ))}
        {!list.length && (
          <div className="empty py-5">
            <Empty />
          </div>
        )}
      </div>
      {!!list.length && (
        <div className="pagination text-right mt-3">
          <Pagination
            current={pagination.pageNo}
            total={pagination.total}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </div>
      )}

      {/* 创建 */}
      {visibleCreate && (
        <CreateForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onBuy}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}

      {(qrcodeVisible && (
        <Modal
          open={qrcodeVisible}
          width={600}
          closable={false}
          footer={[
            <div
              className="price-info text-left"
              style={{
                position: "absolute",
                left: "15px",
                marginTop: "-7px",
              }}
            >
              <div className="agentPrice">
                <span>实付金额：</span>
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: 24,
                    marginRight: 10,
                  }}
                >
                  ￥{price?.price}
                </span>
              </div>
            </div>,
            <Button
              onClick={() => {
                closeQrcode();
              }}
            >
              未完成支付
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                setVisibleCreate(false);
                closeQrcode();
              }}
            >
              已完成支付
            </Button>,
          ]}
        >
          <div className="tips mb-5">
            请确认您购买授权后将不支持退款，支付后相关款项会从微信中扣除，如果您不确定是否有强烈的需求，请您先体验30天后转为正式授权即可。
          </div>
          <div className="text-center">
            <QRCodeCanvas width={300} value={qrcodeUrl} />
          </div>
        </Modal>
      )) ||
        ""}
    </div>
  );
};

export default Device;
